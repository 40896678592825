import JsxParser from 'react-jsx-parser'

export const renderJSXValueCell = (
  // Содержимое самого скрипта
  value: string,
  srcObj: Record<string, unknown>
) => {
  return (
    <div style={{ pointerEvents: 'none' }}>
      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      <JsxParser bindings={{ srcObj }} jsx={value} />
    </div>
  )
}
