import React from 'react'
import { DoneAll } from '@mui/icons-material'
import { MenuItem, Typography } from '@mui/material'

import { AutocompleteOption } from '@types'

export const getAutocompleteOptionDisabledObject = (
  option: AutocompleteOption<string | number>,
  list: string[]
) => {
  return list.includes(String(option.id))
}

export const AutocompleteOptionsObject = (props: {
  otherProps: React.HTMLAttributes<HTMLLIElement>
  option: { id: string | number; label: string }
  list: string[]
}) => {
  const { option, list, otherProps } = props
  const isAlreadyUsed = list.includes(String(option.id))

  return (
    <MenuItem {...otherProps}>
      {isAlreadyUsed && <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />}
      <Typography ml={1}>{option.label}</Typography>
    </MenuItem>
  )
}
