import { GanttActionType } from '@gantt/components/GanttCreateOrEdit'

export const getDefaultValue = (code: number): GanttActionType => {
  return structuredClone({
    id: undefined,
    code: `action_Y_${code}`,
    title: '',
    actionCode: null,
    eventCode: null,
    target: null,
    actionField: null,
  })
}
