import {
  Dispatch,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { FormInputProps } from '@microservices/wiskey-react-components'
import { FormInputsType } from '@microservices/wiskey-react-components/dist/types'
import { DoneAll, ReportProblemOutlined as AlertIcon } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { ObjectValueOptionsType } from '@pages/EntityCreateOrEdit/components/AddColumnDialog/hooks'
import { FieldForm } from '@pages/FormCreateOrEdit/components/AddFieldDialog/helpers/getDefaultValue'

import { useObjectOptions } from '@components/DataSource/useObjectOptions'

import { checkValidJS, getObjectValueName, isObjectValueType } from '@helpers'
import {
  BIND_TYPE,
  FIELD_VALUE_TYPE,
  FORMATS_DATE_OPTIONS,
  GENERATOR_INPUT_TYPE,
  MAX_INPUT_LENGTH,
  MODAL_TYPE,
  OBJECT_FIELD_TYPE,
  REGEX,
} from '@constants'
import {
  AutocompleteOption,
  Dictionary,
  FormRow,
  GETShareColumn,
  ModalType,
  ObjectFieldDTO,
  ObjectFieldWithUsed,
  ObjectShortDTO,
} from '@types'

type UseInputsParams = {
  modifyObjectFields: ObjectFieldWithUsed[] | undefined
  objectFieldsForFilter: ObjectFieldDTO[] | undefined
  currentRow: GETShareColumn | null | undefined
  dropdownEntities: any
  forms: any
  embeddedObjectCode: string
  fields: FormRow[]
  methods: UseFormReturn<FieldForm, any, undefined>
  objectForField: any
  bindingValuesColumns: Dictionary[] | undefined
  objectField: ObjectFieldDTO | undefined
  setIsEdit: Dispatch<SetStateAction<boolean>>
  valueType: FIELD_VALUE_TYPE | null
  srcObj: Record<string, unknown>
  toggleOpenScriptValueDialog: (open: boolean) => void
  modalType: ModalType
  isEdit: boolean
  objects?: ObjectShortDTO[]
}
export const useInputs = ({
  modifyObjectFields,
  objectFieldsForFilter,
  currentRow,
  dropdownEntities,
  forms,
  embeddedObjectCode,
  fields,
  methods,
  objectForField,
  bindingValuesColumns,
  objectField,
  setIsEdit,
  valueType,
  srcObj,
  toggleOpenScriptValueDialog,
  modalType,
  isEdit,
  objects = [],
}: UseInputsParams) => {
  const { t } = useTranslation()

  const { reset, watch, setValue } = methods

  const watchValue = watch('value')
  const watchBinding = watch('bindType')
  const watchDropdownList = watch('dropDownListCode')
  const watchDropdownWindow = watch('dropDownWindowCode')
  const watchJson = watch('json')
  const watchObjectValue = watch('objectValue')
  const watchAsDuration = watch('asDuration')
  const watchAsCheckbox = watch('asCheckbox')
  const watchEdit = watch('editField')
  const watchDefaultPlaceholder = watch('isDefaultPlaceholder')
  const watchIsAddFieldValue = watch('isAddFieldValues')

  const isObjectLinkedValue = isObjectValueType(objectField?.valueType)
  const isDatetimeTypeValue = objectField?.valueType === FIELD_VALUE_TYPE.DATETIME
  const isSyntheticTypeValue =
    objectField?.valueType === FIELD_VALUE_TYPE.INTEGER ||
    objectField?.valueType === FIELD_VALUE_TYPE.STRING

  useEffect(() => {
    if (!isSyntheticTypeValue) {
      setValue('isAddFieldValues', false)
    }
  }, [isSyntheticTypeValue])

  const optionsValue = useMemo(
    () =>
      modifyObjectFields
        ?.map(({ id, name, isAlreadyUsed, type, isPk, required }) => ({
          id,
          name,
          isAlreadyUsed,
          fieldType: type,
          isPk,
          isObjectLevelRequired: required,
        }))
        .filter(({ name }) => name !== '_id') || [],
    [modifyObjectFields]
  )

  const objectOptions = useObjectOptions({ objects })

  const currentValue = useMemo(
    () => objectFieldsForFilter?.find(({ name }) => name === currentRow?.value),
    [objectFieldsForFilter, currentRow]
  )

  const dropdownEntityOptions: AutocompleteOption[] = useMemo(
    () =>
      dropdownEntities?.data.map(entity => ({
        id: entity.code,
        label: entity.title,
      })) ?? [],
    [dropdownEntities]
  )

  const formCodeOptions: AutocompleteOption[] = useMemo(
    () =>
      forms?.map(form => ({
        id: form.code,
        label: form.title,
      })) ?? [],
    [forms, embeddedObjectCode]
  )

  const filterObjectValueOptions = useCallback(
    (objectFields: ObjectFieldDTO[]) =>
      objectFields.map(objectField => {
        const isAlreadyUsed = Boolean(
          fields?.find(field => {
            return field?.value === watchValue && field.objectValue === objectField.name
          })
        )

        return { id: objectField.id, name: objectField.name, isAlreadyUsed }
      }),
    [fields, watchValue, currentRow]
  )

  const objectValueOptions = useMemo(
    () =>
      filterObjectValueOptions(
        objectForField?.fields.filter(obj => !isObjectValueType(obj.valueType)) || []
      ),
    [objectForField, filterObjectValueOptions]
  )

  const bindTypeOptions = useMemo(
    () =>
      bindingValuesColumns
        ?.map(cat => ({
          id: cat.id,
          name: cat.code,
        }))
        .filter(cat => !(cat.name === BIND_TYPE.FIELD && optionsValue.length === 0)) || [],
    [bindingValuesColumns, optionsValue]
  )

  const defaultRequired = objectField && objectField.required
  const valueIsNotEntered = watchValue === i18next.t('placeholder.value') || !watchValue

  const isUserRequiredCheckboxDisabled =
    defaultRequired || watchBinding !== BIND_TYPE.FIELD || valueIsNotEntered

  const isObjectValueDisabledByDropdownList =
    watchDropdownList === t('placeholder.dropDownList') ? false : watchDropdownList !== null

  const isObjectValueDisabledByDropdownWindow =
    watchDropdownWindow === t('placeholder.dropDownWindow') ? false : watchDropdownWindow !== null

  const toggleEditFields = (event: any): void => {
    const value = event?.target?.value
    setIsEdit(value)
    setValue('editField', value)
  }

  const shouldDisplayAsCheckboxField =
    (valueType === FIELD_VALUE_TYPE.INTEGER || valueType === FIELD_VALUE_TYPE.BOOLEAN) &&
    objectField?.type !== OBJECT_FIELD_TYPE.ENUM

  const objectLinkedInputs: FormInputsType[] = [
    {
      name: 'formCodeRow',
      inputs: [
        ...(watchJson
          ? [
              {
                name: 'params',
                inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
                placeholder: t('placeholder.params'),
                label: t('label.params'),
                autocompleteOptions: formCodeOptions,
                rules: { required: watchJson },
              },
            ]
          : [
              {
                name: 'objectFormCode',
                inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                placeholder: t('placeholder.objectFormCode'),
                label: t('label.objectFormCode'),
                autocompleteOptions: formCodeOptions,
              },
            ]),
        {
          name: 'json',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          label: t('label.json'),
          labelPlacement: 'end',
        },
      ],
    },
    {
      name: 'objectValue',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      placeholder: t('placeholder.objectValue'),
      label: t('label.objectValue'),
      selectOptions: objectValueOptions,
      renderOptionSelect: (option: ObjectValueOptionsType) => {
        return option.isAlreadyUsed ? (
          <>
            <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
            <Typography ml={1}>{option.name}</Typography>
          </>
        ) : (
          option.name
        )
      },
      ...(watchObjectValue !== i18next.t('placeholder.objectValue') && {
        renderValueSelect: value => value,
      }),
      // disabled: isObjectValueDisabledByDropdownList || isObjectValueDisabledByDropdownWindow,
      rules: {
        required: true,
        validate: (value: string) => value !== i18next.t('placeholder.objectValue'),
      },
    },
  ]

  const formatDateInput: FormInputsType[] = [
    {
      name: 'formatDate',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: 'Format Date',
      label: 'Format Date',
      autocompleteOptions: FORMATS_DATE_OPTIONS,
    },
  ]

  const valueFieldForFieldBinding: FormInputsType[] = [
    {
      name: 'valueTypeRow',
      inputs: [
        {
          name: 'value',
          inputType: GENERATOR_INPUT_TYPE.SELECT,
          label: t('label.value'),
          placeholder: t('placeholder.value'),
          disabled: !watchBinding || watchBinding === i18next.t('placeholder.bindType'),
          selectOptions: optionsValue,
          MenuProps: { PaperProps: { sx: { maxHeight: 300 } } },
          onChangeSelect: value => {
            setValue('fieldValues', [])
          },
          renderOptionSelect: (option: ObjectValueOptionsType) => {
            return (
              <Box display='flex' justifyContent='space-between' width='100%'>
                <Box alignItems='center' display='flex'>
                  {option.isAlreadyUsed && (
                    <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
                  )}
                  <Typography ml={1}>
                    {getObjectValueName(option.name, option.fieldType, option.isPk)}
                  </Typography>
                </Box>
                {option.isObjectLevelRequired && (
                  <Box sx={{ display: 'flex' }} title={t('form.fieldObjectRequired')}>
                    <AlertIcon
                      sx={{
                        color: theme => theme.palette.color.warning,
                        fontSize: '1.3rem',
                      }}
                    />
                  </Box>
                )}
              </Box>
            )
          },
          ...(watchValue !== i18next.t('placeholder.value') && {
            renderValueSelect: value => {
              const option = optionsValue.find(el => el.name === value)

              if (option) {
                const nameWithPostfix = getObjectValueName(
                  option.name,
                  option.fieldType,
                  option.isPk
                )

                return (
                  <Box title={option.name !== nameWithPostfix ? nameWithPostfix : undefined}>
                    {value as ReactNode}
                  </Box>
                )
              }

              return value
            },
          }),
          rules: {
            required: true,
            validate: (value: string) => value !== i18next.t('placeholder.value'),
          },
        } as FormInputProps,
        ...(shouldDisplayAsCheckboxField
          ? [
              {
                name: 'asCheckbox',
                inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                label: t('label.asCheckbox'),
                labelPlacement: 'end',
                disabled: watchAsDuration || watchIsAddFieldValue,
              } as FormInputProps,
            ]
          : []),
        ...(valueType === FIELD_VALUE_TYPE.INTEGER
          ? [
              {
                name: 'asDuration',
                inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                label: t('label.asDuration'),
                labelPlacement: 'end',
                disabled: watchAsCheckbox || watchIsAddFieldValue,
              } as FormInputProps,
            ]
          : []),
        ...(objectField && !isObjectLinkedValue && isSyntheticTypeValue
          ? [
              {
                name: 'isAddFieldValues',
                inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                label: 'Add Field Value',
                labelPlacement: 'end',
                disabled: watchAsCheckbox || watchAsDuration,
                onChangeCheckbox: value => {
                  if (value) {
                    setValue('isMultiline', false)
                  }
                },
              } as FormInputProps,
            ]
          : []),
      ],
    },
  ]

  const valueTypeForFieldInput: FormInputsType[] = [
    {
      name: 'valueType',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      label: t('label.valueType'),
      placeholder: t('placeholder.valueType'),
      disabled: true,
      value: valueType,
    },
  ]

  const valueFieldForJsBinding: FormInputsType[] = [
    {
      name: 'value',
      inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
      placeholder: t('placeholder.fieldValue'),
      label: t('label.value'),
      maxLengthInput: MAX_INPUT_LENGTH,
      readOnly: true,
      rules: {
        required: true,
        maxLength: MAX_INPUT_LENGTH,
        validate: (value: string) => {
          if (checkValidJS(value, { srcObj }).error) {
            return t('error.valueJS')
          }
        },
      },
      additionalBtn: {
        isEnabled: true,
        text: 'edit',
        color: 'primary',
        variant: 'contained',
        onClick: () => toggleOpenScriptValueDialog(true),
      },
    },
  ]

  const fieldInputs: FormInputsType[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: t('placeholder.internalId'),
      label: t('label.internalId'),
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      disabled: modalType === MODAL_TYPE.EDIT,
      rules: {
        required: true,
        validate: (value: string) => {
          if (fields.find(field => field.code === value && currentRow?.code !== value)) {
            return `${value} is already in used!`
          }
        },
      },
    },
    {
      name: 'titleRow',
      inputs: [
        {
          name: 'title',
          inputType: GENERATOR_INPUT_TYPE.INPUT,
          placeholder: t('placeholder.title'),
          label: t('label.title'),
          rules: { validate: (value: string) => value.trim().length !== 0 },
        },
        {
          name: 'required',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          label: t('label.userRequired'),
          labelPlacement: 'end',
          disabled: isUserRequiredCheckboxDisabled,
        },
        {
          name: 'editField',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          label: t('label.editField'),
          labelPlacement: 'end',
          rules: {
            onChange: (event: MouseEventHandler) => toggleEditFields(event),
          },
          value: Boolean(watchEdit) && isEdit,
          ...(watchBinding === BIND_TYPE.JS && { value: false, disabled: true }),
        },
      ],
    },

    {
      name: 'placeholderRow',
      inputs: [
        {
          name: 'placeholderValue',
          inputType: GENERATOR_INPUT_TYPE.INPUT,
          placeholder: t('placeholder.placeholder'),
          label: t('label.placeholder'),
          disabled: watchDefaultPlaceholder,
        },
        ...(valueType === FIELD_VALUE_TYPE.STRING
          ? [
              {
                name: 'isMultiline',
                inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                label: t('label.multiline'),
                labelPlacement: 'end',
                disabled: watchIsAddFieldValue,
              } as FormInputProps,
            ]
          : []),
        {
          name: 'isDefaultPlaceholder',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          label: t('label.placeholderCheckbox'),
          labelPlacement: 'end',
        },
      ],
    },
    {
      name: 'bindType',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      placeholder: t('placeholder.bindType'),
      label: t('label.bindType'),
      selectOptions: bindTypeOptions,
      onChangeSelect: value => {
        if (value === BIND_TYPE.JS) {
          setValue('editField', false)
        }

        if (value === BIND_TYPE.FIELD) {
          setValue('editField', true)
        }
      },
      rules: { required: true, validate: value => value !== t('placeholder.bindType') },
    },
    ...(isObjectLinkedValue ? objectLinkedInputs : []),
    ...(watchBinding === BIND_TYPE.JS ? valueFieldForJsBinding : valueFieldForFieldBinding),
    ...(isDatetimeTypeValue ? formatDateInput : []),
  ]

  const preFillSourceObjectInput: FormInputsType[] = [
    {
      name: 'preFillSourceObject',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: t('placeholder.preFillSourceObject'),
      label: t('label.preFillSourceObject'),
      autocompleteOptions: objectOptions,
    },
  ]

  const dropwDownListFormInput: FormInputsType = {
    name: 'dropDownListCode',
    inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
    placeholder: t('placeholder.dropDownList'),
    label: t('label.dropDownList'),
    autocompleteOptions: dropdownEntityOptions,
  }

  const dropwDownWindowFormInput: FormInputsType = {
    name: 'dropDownWindowCode',
    inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
    placeholder: t('placeholder.dropDownWindow'),
    label: t('label.dropDownWindow'),
    autocompleteOptions: dropdownEntityOptions,
  }

  return {
    inputs: {
      fieldInputs,
      dropwDownListFormInput,
      dropwDownWindowFormInput,
      valueTypeForFieldInput,
      preFillSourceObjectInput,
    },
  }
}
