import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import i18next from 'i18next'
import last from 'lodash/last'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'

import { useFetchAllFormQuery, useFetchObjectByCodeNoCacheMutation } from '@redux/api'

import { usePrompt, useYupValidationResolver } from '@hooks'
import { getNewLastIndexByData } from '@helpers'
import { MODEL_TYPE } from '@constants'
import { SelectOption } from '@types'

import {
  GANTT_BIND_TYPE_LIST,
  GanttActionType,
  PageContext,
} from '@gantt/components/GanttCreateOrEdit'
import {
  getCommandsFormattedRequest,
  getCommandsFormattedResponse,
  getOptionByStr,
  isCellTarget,
} from '@gantt/helpers'
import { getIsDuplicateError } from '@gantt/helpers/getIsDuplicateError'

import { ganttActionFormSchema } from '@validations'

import { getDefaultValue } from '../helpers'

import { useInputs } from './useInputs'

export const useAddGanttActionDialog = () => {
  const {
    onSetModalProperty,
    bindingValuesResourceAction,
    bindingValuesActionResourceEvents,
    objectCodeResource,
    onSetCurrentActionResource,
    currentRow,
    currentColumnsList,
    currentActionResourceList,
    modalType,
  } = useContext(PageContext)

  const defaultValues = getDefaultValue(
    getNewLastIndexByData({
      items: currentActionResourceList,
      getterNumber: value => last(value?.code?.split('_')),
    })
  )
  const resolver = useYupValidationResolver(ganttActionFormSchema)
  const methods = useForm<GanttActionType>({
    defaultValues,
    resolver,
  })

  const [key, setKey] = useState(false)

  const {
    formState: { isDirty },
    watch,
    handleSubmit,
    reset,
    setError,
    clearErrors,
  } = methods

  const [fetchObjectByCode, { data: objectByCode }] = useFetchObjectByCodeNoCacheMutation({})

  const watchEventCode = watch('eventCode')
  const watchActionCode = watch('actionCode')
  const watchActionFieldBindType = watch('actionField.bindType')

  const objectCode = objectCodeResource

  const {
    data: forms,
    isFetching: isFetchingAllForm,
    isLoading: isLoadingAllForm,
  } = useFetchAllFormQuery(
    { objectCode },
    {
      skip: !objectCodeResource,
    }
  )

  const isLoading = isFetchingAllForm || isLoadingAllForm

  usePrompt({ when: isDirty })

  useEffect(() => {
    if (objectCodeResource) {
      fetchObjectByCode(objectCodeResource)
    }
  }, [objectCodeResource])

  const formCodeOptions: AutocompleteOption[] = useMemo(
    () =>
      forms?.data.map(form => ({
        id: form.code,
        label: form.title,
      })) ?? [],
    [forms]
  )

  const actionTypeOptions: SelectOption[] = useMemo(
    () => bindingValuesResourceAction?.map(({ id, code: name }) => ({ id, name })) || [],
    [bindingValuesResourceAction]
  )

  const eventOptions: SelectOption[] = useMemo(
    () => bindingValuesActionResourceEvents?.map(({ id, code: name }) => ({ id, name })) || [],
    [bindingValuesActionResourceEvents]
  )

  const targetOptions = useMemo(
    () => currentColumnsList?.map(column => getOptionByStr(column.code, column.title)),
    [currentColumnsList]
  )

  const actionInputs = useInputs({
    actionTypeOptions,
    eventOptions,
    watchEventCode,
    targetOptions,
    methods,
    watchActionCode,
  })

  useEffect(() => {
    objectCodeResource && fetchObjectByCode(objectCodeResource)
  }, [objectCodeResource])

  useEffect(() => {
    onSetModalProperty?.(MODEL_TYPE.GANTT_ACTION_RESOURCE)
  }, [])

  useEffect(() => {
    setKey(!key)
  }, [])

  useEffect(() => {
    if (
      currentRow &&
      formCodeOptions.length !== -1 &&
      !isLoading &&
      actionTypeOptions.length &&
      eventOptions.length
    ) {
      const currentActionRow = structuredClone(currentRow)
      const preparedActionField =
        currentActionRow.actionField.bindType === GANTT_BIND_TYPE_LIST.COMMANDS
          ? {
              ...currentActionRow.actionField,
              commands: getCommandsFormattedResponse(currentActionRow.actionField.commands),
            }
          : currentActionRow.actionField

      currentActionRow.actionField = preparedActionField

      reset(currentActionRow)
      setKey(!key)
    }
  }, [currentRow, isLoading, formCodeOptions, actionTypeOptions, eventOptions])

  const handleDuplicateError = useCallback((error: boolean) => {
    if (error) {
      setError('code', {
        type: 'custom',
        message: i18next.t('error.duplicatedSystemName'),
      })

      return
    }

    clearErrors('code')
  }, [])

  const handleSave = (data: GanttActionType) => {
    const { id = Date.now(), code, title, actionCode, eventCode, actionField, target } = data
    const preparedActionField =
      actionField.bindType === GANTT_BIND_TYPE_LIST.COMMANDS
        ? {
            ...actionField,
            commands: getCommandsFormattedRequest(actionField.commands),
          }
        : actionField

    const isDuplicateError = getIsDuplicateError({
      itemsList: currentActionResourceList,
      value: code,
      modalType,
    })

    handleDuplicateError(isDuplicateError)

    !isDuplicateError &&
      onSetCurrentActionResource({
        id,
        code,
        title,
        actionCode,
        eventCode,
        target: isCellTarget(eventCode) ? target : undefined,
        actionField: preparedActionField,
      })
  }

  return {
    isDirty,
    actionInputs,
    methods,
    handleSave,
    handleSubmit,
    isLoading,
    key,
    formCodeOptions,
    watchActionCode,
    watchActionFieldBindType,
  }
}
