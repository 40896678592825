import { GANTT_BIND_TYPE_LIST, SectionType } from '@gantt/components/GanttCreateOrEdit'
import {
  EMPTY_COMMAND_FORM,
  RESOURCE_KEY_FIELD_PREFIX,
  RESOURCE_LINK_FIELD_PREFIX,
} from '@gantt/constants'

const fieldDefaultWithCommands = {
  field: '',
  pathStr: '',
  pathArray: [],
  commands: EMPTY_COMMAND_FORM,
}

const fieldDefault = {
  field: '',
  pathStr: '',
  pathArray: [],
}

export const getGanttSegmentDefaultValue = (code: number): SectionType => {
  const systemName = `segment_${code}`

  return {
    id: '',
    title: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: '',
    },
    tooltip: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD_ARRAY,
      field_array: [fieldDefault],
    },
    datetimeStart: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: fieldDefaultWithCommands,
    },
    datetimeEnd: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: fieldDefaultWithCommands,
    },
    duration: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: fieldDefault,
    },
    link: {
      axisY: {
        bindType: GANTT_BIND_TYPE_LIST.FIELD,
        field: fieldDefaultWithCommands,
      },
      axisX: {
        bindType: GANTT_BIND_TYPE_LIST.FIELD,
        field: fieldDefaultWithCommands,
      },
      resourceKeyField: RESOURCE_KEY_FIELD_PREFIX,
      resourceLinkField: RESOURCE_LINK_FIELD_PREFIX,
      systemName,
    },
    background: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: '',
    },
    labels: {
      leftTopTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      bottomTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      leftBottomTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      rightBottomTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      rightTopTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      topTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
    },
  }
}
