import { RefObject, useContext, useMemo, useState } from 'react'
import { UseFormHandleSubmit, UseFormReset, UseFormSetValue } from 'react-hook-form'
import isEqual from 'lodash.isequal'
import { ObjectFieldDTO, ShortDisplayRule, ViewRow } from 'src/types'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'

import {
  getInitialPropertiesForRequest,
  getPropertiesForUpdate,
} from '@pages/Parameters/helpers/getPropertiesForUpdate'

import {
  ExternalTextSettings,
  GetTextParamsHandle,
  TextParams,
  TextSettingsStateType,
} from '@components/TextSettings'

import {
  useCreateColumnViewMutation,
  useFetchAllDisplayRulesQuery,
  useUpdateColumnViewMutation,
} from '@redux/api'
import { useUpdateLocalParameterPropertyMutation } from '@redux/api/parameters.api'

import {
  getColumnParametersFromArray,
  getParametersForCreation,
  mapValueTypeToValueFormat,
} from '@helpers'
import {
  BIND_TYPE,
  DEFAULT_GLOBAL_COLUMN_HEADER_PARAMETERS,
  DEFAULT_GLOBAL_COLUMN_TEXT_PARAMETERS,
  DEFAULT_INITIAL_STATE_COLUMN_HEADER_PARAMETERS,
  DEFAULT_INITIAL_STATE_COLUMN_TEXT_PARAMETERS,
  MODAL_TYPE,
  PINNED_COLUMN,
} from '@constants'

import { PageContext } from '../../../EntityCreateOrEdit'
import { getDefaultValue } from '../helpers'

import { ColumnForm } from './useInputs'

type ColumnSuccessCallbackParams = {
  columnData: ViewRow
  columnHeaderData: TextParams | undefined
  columnTextData: TextParams | undefined
}

type useHandlersParams = {
  objectField: ObjectFieldDTO | undefined
  watchBinding?: BIND_TYPE
  watchUseParameters: boolean
  setValue: UseFormSetValue<ColumnForm>
  isDirty: boolean
  isObjectLinkedValue: boolean
  reset: UseFormReset<ColumnForm>
  handleSubmit: UseFormHandleSubmit<ColumnForm>
  viewObjectCode: string
  columnHeaderSettingsRef: RefObject<GetTextParamsHandle>
  columnTextSettingsRef: RefObject<GetTextParamsHandle>
  initialColumnHeaderStyles: TextSettingsStateType | null
  initialColumnTextStyles: TextSettingsStateType | null
  watchUseDisplayRule?: boolean
  watchDisplayRule?: AutocompleteOption<string | number> | null | undefined
}

export const useHandlers = ({
  objectField,
  watchBinding,
  watchUseParameters,
  setValue,
  isDirty,
  isObjectLinkedValue,
  reset,
  handleSubmit,
  viewObjectCode,
  columnHeaderSettingsRef,
  columnTextSettingsRef,
  initialColumnHeaderStyles,
  initialColumnTextStyles,
  watchUseDisplayRule,
  watchDisplayRule,
}: useHandlersParams) => {
  const defaultValues: ColumnForm = getDefaultValue()

  const [isDirtyHeader, setDirtyHeader] = useState(false)
  const [isDirtyText, setDirtyText] = useState(false)
  const [isShowScriptValueDialog, setScriptValueDialog] = useState(false)
  const [isShowResetConfirmModal, setShowResetConfirmModal] = useState(false)

  const { currentRow, onCloseModal, modalType, entityCode, rawObjectFields, currentEntity } =
    useContext(PageContext)

  const viewType = currentEntity?.type

  const [createColumn] = useCreateColumnViewMutation()
  const [updateColumn] = useUpdateColumnViewMutation()
  const [updateParameterProperty] = useUpdateLocalParameterPropertyMutation()

  const { data: displayRulesData, isFetching: isLoadingDisplayRules } =
    useFetchAllDisplayRulesQuery(
      {
        objectCode: currentRow?.objectCode ? currentRow?.objectCode : viewObjectCode,
        objectField:
          currentRow?.bindType === BIND_TYPE.FIELD && currentRow?.value
            ? currentRow.value
            : undefined,
      },
      {
        skip: !watchUseDisplayRule,
        refetchOnMountOrArgChange: true,
      }
    )

  const autocompleteOptionsDisplayRules = useMemo(() => {
    if (displayRulesData) {
      return (displayRulesData as unknown as ShortDisplayRule[]).map(displayRule => ({
        id: displayRule.id,
        label: displayRule.code,
      }))
    }
  }, [displayRulesData])

  const handleTextExternalDirty = (object: ExternalTextSettings) => {
    if (object && initialColumnTextStyles) {
      const isDirty = !isEqual(object, initialColumnTextStyles)

      setDirtyText(isDirty)
    }
  }

  const handleHeaderExternalDirty = (object: ExternalTextSettings) => {
    if (object && initialColumnTextStyles) {
      const isDirty = !isEqual(object, initialColumnHeaderStyles)

      setDirtyHeader(isDirty)
    }
  }

  const handleChangeValue = (value: string | number) => {
    const objectFieldValue = rawObjectFields?.find(obj => obj.name === value)

    if (watchBinding === BIND_TYPE.FIELD && objectFieldValue) {
      const selectValue = { id: 0, label: objectFieldValue.name }
      setValue('columnToSort', selectValue, { shouldDirty: true })
    }
  }

  const toggleOpenScriptValueDialog = (open: boolean) => setScriptValueDialog(open)

  const handleChangeBinding = (value: string | number) => {
    if ((modalType !== MODAL_TYPE.EDIT && !isDirty) || (value && value === BIND_TYPE.FIELD)) {
      setValue('columnToSort', null)
    }
  }

  const handleCreateColumnSuccess = ({
    columnHeaderData,
    columnTextData,
    columnData,
  }: ColumnSuccessCallbackParams) => {
    if (!columnHeaderData || !columnTextData || !columnData?.parameters) {
      return
    }

    const { headerParamId, textParamId } = getColumnParametersFromArray(columnData.parameters)

    if (!watchUseParameters || !textParamId || !headerParamId) {
      return
    }

    const propertiesForCreation = [
      ...getInitialPropertiesForRequest({
        textData: columnHeaderData,
        paramId: headerParamId,
      }),
      ...getInitialPropertiesForRequest({
        textData: columnTextData,
        paramId: textParamId,
      }),
    ]

    updateParameterProperty(propertiesForCreation)
  }

  const handleUpdateColumnSuccess = ({
    columnData,
    columnHeaderData,
    columnTextData,
  }: ColumnSuccessCallbackParams) => {
    if (!columnHeaderData || !columnTextData || !columnData?.parameters) {
      return
    }

    const { headerProperties, textProperties, headerParamId, textParamId } =
      getColumnParametersFromArray(columnData.parameters)

    if (!headerProperties || !textProperties || !headerParamId || !textParamId) {
      return
    }

    if (headerProperties.length === 0 && textProperties.length === 0) {
      handleCreateColumnSuccess({
        columnHeaderData,
        columnTextData,
        columnData,
      })
    }

    const propertiesForUpdate = [
      ...getPropertiesForUpdate(columnHeaderData, headerProperties, headerParamId),
      ...getPropertiesForUpdate(columnTextData, textProperties, textParamId),
    ]

    updateParameterProperty(propertiesForUpdate)
  }

  const handleSave = (
    columnHeaderData: TextParams | undefined,
    columnTextData: TextParams | undefined
  ) => {
    handleSubmit((data: ColumnForm) => {
      const {
        id = Date.now(),
        code,
        bindType = BIND_TYPE.FIELD,
        title,
        value,
        objectValue,
        columnToSort,
        pinning,
        pinnedColumn: _pinnedColumn,
        enumDescription,
        useDisplayRule,
        displayRuleId,
        asCheckbox,
        asDuration,
        formatDate,
      } = data
      const pinnedColumn = (_pinnedColumn?.id as PINNED_COLUMN) || null
      const columnHeaderCode = `column_header_styles_${code}`
      const columnTextCode = `column_text_styles_${code}`

      const valueFormat = {
        valueFormat: objectField?.valueType
          ? mapValueTypeToValueFormat(objectField?.valueType, asCheckbox, asDuration)
          : null,
      }

      if (modalType === MODAL_TYPE.EDIT && currentRow) {
        updateColumn({
          id,
          code,
          bindType,
          title,
          value,
          objectValue: isObjectLinkedValue ? objectValue : undefined,
          viewCode: entityCode,
          viewType,
          objectCode: viewObjectCode,
          columnToSort: columnToSort?.label,
          pinnedColumn: pinning ? pinnedColumn : null,
          useLocalParameters: watchUseParameters,
          // Если решаем использовать параметры, но их на данный момент нет, то создаем их
          ...(watchUseParameters && !currentRow.parameters.length
            ? { parameters: getParametersForCreation(columnHeaderCode, columnTextCode) }
            : {}),
          enumDescription,
          useDisplayRule,
          displayRuleId: watchDisplayRule?.id,
          formatDate: formatDate ? formatDate.label : null,
          ...valueFormat,
        })
          .unwrap()
          .then(columnData => {
            handleUpdateColumnSuccess({
              columnData,
              columnHeaderData,
              columnTextData,
            })
            onCloseModal()
          })

        return
      }

      createColumn({
        code,
        bindType,
        title,
        value,
        objectValue: isObjectLinkedValue ? objectValue : undefined,
        viewCode: entityCode,
        viewType,
        objectCode: viewObjectCode,
        columnToSort: columnToSort?.label,
        pinnedColumn: pinning ? pinnedColumn : null,
        useLocalParameters: watchUseParameters,
        parameters: watchUseParameters
          ? getParametersForCreation(columnHeaderCode, columnTextCode)
          : [],
        enumDescription,
        useDisplayRule,
        displayRuleId: watchDisplayRule?.id,
        formatDate: formatDate ? formatDate.label : null,
        ...valueFormat,
      })
        .unwrap()
        .then(columnData => {
          handleCreateColumnSuccess({
            columnData,
            columnHeaderData,
            columnTextData,
          })

          reset(defaultValues)
          onCloseModal()
        })
    })()
  }

  const handleSetValuesColumnStyles = (
    columnHeaderStyles: TextSettingsStateType,
    columnTextStyles: TextSettingsStateType
  ) => {
    columnHeaderSettingsRef.current?.setValues(columnHeaderStyles)
    columnTextSettingsRef.current?.setValues(columnTextStyles)
  }

  const handleResetParams = () => {
    handleSetValuesColumnStyles(
      DEFAULT_INITIAL_STATE_COLUMN_HEADER_PARAMETERS,
      DEFAULT_INITIAL_STATE_COLUMN_TEXT_PARAMETERS
    )
    setShowResetConfirmModal(false)
  }

  const handleClickReset = () => {
    const columnHeaderData = columnHeaderSettingsRef.current?.getTextParams()
    const columnTextData = columnTextSettingsRef.current?.getTextParams()

    if (
      isEqual(columnHeaderData, DEFAULT_GLOBAL_COLUMN_HEADER_PARAMETERS) &&
      isEqual(columnTextData, DEFAULT_GLOBAL_COLUMN_TEXT_PARAMETERS)
    ) {
      return
    }

    setShowResetConfirmModal(true)
  }

  const handleSetShowResetConfirmModal = (value: boolean) => setShowResetConfirmModal(value)

  return {
    data: {
      autocompleteOptionsDisplayRules,
      isLoadingDisplayRules,
    },
    state: {
      isShowScriptValueDialog,
      isTextParamsDirty: isDirtyHeader || isDirtyText,
      isShowResetConfirmModal,
    },
    handlers: {
      handleChangeValue,
      handleChangeBinding,
      toggleOpenScriptValueDialog,
      handleSave,
      handleTextExternalDirty,
      handleHeaderExternalDirty,
      handleSetValuesColumnStyles,
      handleSetShowResetConfirmModal,
      handleResetParams,
      handleClickReset,
    },
  }
}
