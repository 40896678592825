import {
  AXIS_TYPE,
  GANTT_BIND_TYPE_LIST,
  GanttFormValues,
  SegmentType,
  UNIT_INTERVAL_OPTIONS,
} from '@gantt/components/GanttCreateOrEdit'
import { EMPTY_COMMAND_FORM } from '@gantt/constants/forms'

export const DefReadAll = 'def_read_all'

export const contextMenuDefaultJson = [
  {
    name: 'Sorting',
    isHeader: true,
  },
  {
    name: 'Sort by ASC',
    command: 'sort',
    additionalData: {
      order: 'asc',
    },
  },
  {
    name: 'Sort by DESC',
    command: 'sort',
    additionalData: {
      order: 'desc',
    },
  },
  {
    isDivider: true,
  },
  {
    name: 'Unsort',
    command: 'unsort',
  },
]

export const contextMenuDefaultWithCommandJson = [
  {
    name: 'Get all',
    commands: [
      {
        name: 'object_read_all',
      },
    ],
  },
]

export const tooltipDefaultTextJson = [
  {
    name: { bindType: 'static', static: 'text' },
    data: { bindType: 'static', static: 'value' },
    filler: 'value',
    parameters: [],
    itemType: 'text',
  },
]

export const tooltipDefaultGroupJson = [
  {
    name: { bindType: 'static', static: 'group' },
    itemType: 'group',
    items: tooltipDefaultTextJson,
  },
]

export const ganttConfigDefaultValue: GanttFormValues = {
  code: '',
  title: '',
  canvas: {
    axisX: {
      hasRealtimeLine: true,
      realtimeLineWidth: '2',
      realtimeLineColor: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '#FF0000',
      },
      pastBackgroundColor: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: undefined,
      },
      futureBackgroundColor: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: undefined,
      },
      unitInterval: UNIT_INTERVAL_OPTIONS.QUARTER,
      hasToolbar: false,
      extraVirtualizationCells: '',
    },
    axisY: {
      sortable: false,
      extraVirtualizationRows: '',
    },
    hasBacklog: false,
    rowHeight: '50',
    backlogHeight: '300',
    yAxisWidth: '461',
    xAxisWidth: '',
    noRealTime: false,
    rowExpansionHeight: '100',
    hasRowExpansion: false,
    isResizableBacklog: false,
    isResizableY: false,
  },
  timeline: [
    {
      segmentType: SegmentType.MULTI,
      actions: [],
      additionalFields: [],
      sections: [],
      data: null,
      commands: EMPTY_COMMAND_FORM,
      hasCorners: false,
      title: {
        bindType: GANTT_BIND_TYPE_LIST.FIELD,
        field: undefined,
      },
      tooltip: {
        bindType: GANTT_BIND_TYPE_LIST.FIELD,
        field: undefined,
      },
      background: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: undefined,
      },
      filter: {
        static: undefined,
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
      },
      key: {
        bindType: GANTT_BIND_TYPE_LIST.FIELD,
        field: undefined,
      },
    },
  ],
  resource: {
    data: null,
    commands: EMPTY_COMMAND_FORM,
    columns: [],
    actions: [],
    filter: {
      static: '',
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
    },
    key: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: undefined,
    },
  },
  sort: {
    axis: AXIS_TYPE.Y,
    code: '',
    value: [
      {
        field: null,
        order: null,
      },
    ],
  },
}
