import {
  CopyControlPanelPayload,
  CREATEControlPanel,
  GETControlPanel,
  GETObjectDataParams,
  UPDATEControlPanel,
} from '@types'

import { commonAPI } from './common.api'

export const controlPanelApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchControlPanelList: build.query<GETControlPanel[], GETObjectDataParams>({
      query: params => ({
        url: 'ui/menu',
        params: {
          page: params?.page,
          size: params?.size,
          sort: params?.sort,
          searchPattern: params?.searchPattern,
        },
      }),
      providesTags: ['ControlPanels'],
    }),
    fetchControlPanelById: build.query<GETControlPanel, string | void>({
      query: (id: string) => ({
        url: `ui/menu/id/${id}`,
      }),
      providesTags: ['SingleControlPanel'],
    }),
    createControlPanel: build.mutation<GETControlPanel, CREATEControlPanel>({
      query: elements => ({
        url: `ui/menu`,
        method: 'POST',
        body: elements,
      }),
      invalidatesTags: ['ControlPanels'],
    }),
    updateControlPanel: build.mutation<GETControlPanel, UPDATEControlPanel>({
      query: ({ elements }) => ({
        url: `ui/menu/`,
        method: 'POST',
        body: elements,
      }),
      invalidatesTags: ['ControlPanels', 'SingleControlPanel'],
    }),
    copyControlPanel: build.mutation<GETControlPanel, CopyControlPanelPayload>({
      query: controlPanelData => ({
        url: 'ui/menu/copy',
        method: 'POST',
        body: controlPanelData,
      }),
      invalidatesTags: ['ControlPanels'],
    }),
    deleteControlPanel: build.mutation<GETControlPanel, string | number>({
      query: id => ({
        url: `ui/menu/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ControlPanels'],
    }),
  }),
})

export const {
  useFetchControlPanelByIdQuery,
  useFetchControlPanelListQuery,
  useUpdateControlPanelMutation,
  useCopyControlPanelMutation,
  useDeleteControlPanelMutation,
  useCreateControlPanelMutation,
} = controlPanelApi
