import { FC } from 'react'
import { RegisterOptions, useController, useFormContext } from 'react-hook-form'
import { AutocompleteOption } from 'src/types'

import {
  SyntheticAutoComplete,
  SyntheticAutoCompleteProps,
} from '@components/ui/SyntheticAutoComplete'

type SyntheticAutoCompleteControllerProps<T> = Omit<
  SyntheticAutoCompleteProps<T>,
  'value' | 'onChange'
>

export const SyntheticAutoCompleteController: FC<
  SyntheticAutoCompleteControllerProps<AutocompleteOption> & {
    rules?: RegisterOptions
  }
> = ({ ...props }) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value: formValue },
    fieldState: { invalid },
  } = useController({
    name: props.name,
    control,
    rules: props.rules,
  })

  return (
    <SyntheticAutoComplete invalid={invalid} value={formValue} onChange={onChange} {...props} />
  )
}
