import { useCallback, useContext, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'
import { INPUT_TYPE } from '@components/BindTypeInputs/ValueInputFactory'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { AutocompleteOption } from '@types'

import { AccordionConfigExamples, BtnCopyConfigExample } from '@gantt/components/ConfigExamples'
import {
  AvailableVariables,
  BACKGROUND_BINDING_TYPE,
  GANTT_BIND_TYPE_LIST,
  PageContext,
  SegmentFieldType,
  TITLE_BINDING_TYPE,
  TOOLTIP_BINDING_TYPE,
} from '@gantt/components/GanttCreateOrEdit'
import { tooltipAvailableKeys } from '@gantt/constants'
import { checkValidGanttJS, getBindTypeOptionsByEnum } from '@gantt/helpers'
import { checkValidGanttJSON } from '@gantt/helpers/checkValidGanttJSON'
import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

import { VariablesList } from '../VariablesList'

type BaseFormFieldsProps = {
  disabled: boolean
  isShowScriptDialog?: boolean
  isShownCornersInput?: boolean
  mainPath?: string
  watchedObject?: AutocompleteOption<string> | null
  toggleOpenScriptValueDialog: () => void
  currentBarKey?: string
  keyValue?: string
  variablesForJS?: AvailableVariables
  existingPaths?: string[]
}

const colorHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.backgroundColorScriptHint'),
  static: i18next.t('ganttCreate.timelineForm.hint.backgroundColorStaticHint'),
}

const tooltipHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.tooltipScriptHint'),
}

const tooltipPlaceholders = {
  js: i18next.t('ganttCreate.timelineForm.placeholder.tooltipJS'),
}

const titleHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.titleScriptHint'),
}

const titlePlaceholders = {
  js: i18next.t('ganttCreate.timelineForm.placeholder.titleJS'),
}

export const BaseFormFields = ({
  disabled,
  isShowScriptDialog = false,
  isShownCornersInput = false,
  mainPath,
  watchedObject,
  toggleOpenScriptValueDialog,
  keyValue,
  currentBarKey,
  variablesForJS,
  existingPaths,
}: BaseFormFieldsProps) => {
  const { watch } = useFormContext()
  const { availableVariables } = useContext(PageContext)
  const { t } = useTranslation()
  const variables = variablesForJS || availableVariables

  const getPath = (path: string) => {
    if (mainPath) {
      return `${mainPath}.${path}`
    }

    return `${path}`
  }

  const watchedTitleBindType = watch(getPath('title')).bindType
  const watchedTooltipBindType = watch(getPath('tooltip')).bindType
  const watchedColorBindType = watch(getPath('background')).bindType

  const getPrefix = (type: SegmentFieldType) => {
    return [currentBarKey, keyValue, type].filter(s => !!s).join('_')
  }

  const funValidator = (
    value: string,
    returnValueType: GANTT_SCRIPT_RETURN_TYPE,
    keys?: string[],
    bindType?: GANTT_BIND_TYPE_LIST.JS | GANTT_BIND_TYPE_LIST.JSON
  ) => {
    if (bindType === GANTT_BIND_TYPE_LIST.JSON) {
      return checkValidGanttJSON(value, returnValueType, keys)
    }

    return checkValidGanttJS(value, variables, returnValueType, keys)
  }

  const funValidatorString = useCallback((value: string) => {
    return funValidator(value, GANTT_SCRIPT_RETURN_TYPE.STRING)
  }, [])

  const funValidatorTooltip = useCallback(
    (value: string) => {
      return funValidator(
        value,
        GANTT_SCRIPT_RETURN_TYPE.ARRAY_OF_OBJECTS,
        tooltipAvailableKeys,
        watchedTooltipBindType
      )
    },
    [watchedTooltipBindType]
  )

  const fieldPicker = useMemo(() => {
    return {
      existingPaths,
    }
  }, [existingPaths])

  return (
    <>
      <BindTypeInputs
        isFieldsetMarkup
        bindTypeOptions={getBindTypeOptionsByEnum(TITLE_BINDING_TYPE)}
        containerName={getPath('title')}
        fieldPicker={fieldPicker}
        fieldsetTitle={t('ganttCreate.timelineForm.generalTitle')}
        hintDict={titleHints}
        isDisabled={disabled}
        placeholderDict={titlePlaceholders}
        prefix={getPrefix(SegmentFieldType.TITLE)}
        watchedObject={watchedObject}
        scriptEditor={{
          validator: funValidatorString,
        }}
      />
      {[TITLE_BINDING_TYPE.JS].includes(watchedTitleBindType) && (
        <AccordionConfigExamples>
          <VariablesList variables={variables} />
        </AccordionConfigExamples>
      )}
      <BindTypeInputs
        isFieldsetMarkup
        bindTypeOptions={getBindTypeOptionsByEnum(TOOLTIP_BINDING_TYPE)}
        containerName={getPath('tooltip')}
        fieldPicker={fieldPicker}
        fieldsetTitle={t('ganttCreate.timelinesObjectTooltips')}
        hintDict={tooltipHints}
        isDisabled={disabled}
        placeholderDict={tooltipPlaceholders}
        prefix={getPrefix(SegmentFieldType.TOOLTIP)}
        watchedObject={watchedObject}
        scriptEditor={{
          validator: funValidatorTooltip,
        }}
      />
      {[TOOLTIP_BINDING_TYPE.JS, TOOLTIP_BINDING_TYPE.JSON].includes(watchedTooltipBindType) && (
        <AccordionConfigExamples>
          <VariablesList variables={variables} />
        </AccordionConfigExamples>
      )}
      <Box alignItems='center' display='flex' mb={1}>
        <Box pr={1}>{t('ganttCreate.configExamples.blockTitle')}</Box>
        <BtnCopyConfigExample name={t('ganttCreate.configExamples.simpleTooltip')} />
        <BtnCopyConfigExample name={t('ganttCreate.configExamples.groupTooltip')} />
      </Box>
      <BindTypeInputs
        isFieldsetMarkup
        bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
        containerName={getPath('background')}
        fieldsetTitle={t('ganttCreate.timelineForm.background')}
        hintDict={colorHints}
        inputType={INPUT_TYPE.COLOR}
        watchedObject={watchedObject}
        scriptEditor={{
          validator: funValidatorString,
        }}
      />
      {[BACKGROUND_BINDING_TYPE.JS].includes(watchedColorBindType) && (
        <AccordionConfigExamples>
          <VariablesList variables={variables} />
        </AccordionConfigExamples>
      )}
      {isShownCornersInput && (
        <Box component='fieldset'>
          <legend>{t('ganttCreate.timelineForm.parameters')}</legend>
          <FormInput
            inputType={GENERATOR_INPUT_TYPE.CHECKBOX}
            label={t('ganttCreate.timelineForm.hasCorners')}
            labelPlacement='end'
            name={getPath('hasCorners')}
          />
        </Box>
      )}
    </>
  )
}
