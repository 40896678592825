export const getDefaultValue = (code: number) => {
  return structuredClone({
    id: undefined,
    code: `action_X_${code}`,
    title: '',
    actionCode: null,
    eventCode: null,
    target: null,
    actionField: null,
  })
}
