import { FC, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import sum from 'lodash/sum'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'
import { Tab, TabPanel, Tabs } from '@components/Tabs'

import { GENERATOR_INPUT_TYPE, REGEX } from '@constants'
import { ObjectFieldDTO } from '@types'

import {
  ConfigField,
  PageContext,
  SegmentFieldType,
  SegmentType,
} from '@gantt/components/GanttCreateOrEdit'
import { GanttSegmentLinksHelper } from '@gantt/components/GanttCreateOrEdit/components/GanttConfiguration/components/AddSegmentDialog/GanttSegmentLinksHelper'
import { RESOURCE_KEY_FIELD_PREFIX, RESOURCE_LINK_FIELD_PREFIX } from '@gantt/constants'
import {
  dateTimeFieldOptionsFilter,
  durationFieldOptionsFilter,
  keyFieldOptionsFilter,
} from '@gantt/helpers'

import { BaseFormFields, SegmentLabelsFields } from '../../components'

//todo actual?
type Props = {
  type: SegmentType
  objectFieldsForValidation?: ObjectFieldDTO[]
}

export const SegmentItem: FC<Props> = () => {
  const { t } = useTranslation()
  const { watch, setValue, resetField, setError, clearErrors } = useFormContext()
  const [isShowScriptDialog, setIsShowScriptDialog] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const { watchedObjectResource, modalType, currentBarObject, currentBarIndex, existingPaths } =
    useContext(PageContext)

  const watchResourceKeyField = watch(`link.resourceKeyField`)
  const watchSystemName = watch(`link.systemName`)
  const watchResourceLinkField = watch(`link.resourceLinkField`)
  const watchLinkAxisX = watch(`link.axisX`)
  const watchLinkAxisY = watch(`link.axisY`)
  const watchDatetimeStart = watch('datetimeStart')
  const watchDatetimeEnd = watch('datetimeEnd')
  const watchDuration = watch('duration')

  const isNoBarObject = !currentBarObject

  useEffect(() => {
    if (watchResourceKeyField !== `${RESOURCE_KEY_FIELD_PREFIX}${watchSystemName}`) {
      !watchSystemName
        ? resetField(`link.resourceKeyField`)
        : setValue(`link.resourceKeyField`, `${RESOURCE_KEY_FIELD_PREFIX}${watchSystemName}`)
    }

    if (watchResourceLinkField !== `${RESOURCE_LINK_FIELD_PREFIX}${watchSystemName}`) {
      !watchSystemName
        ? resetField(`link.resourceLinkField`)
        : setValue(`link.resourceLinkField`, `${RESOURCE_LINK_FIELD_PREFIX}${watchSystemName}`)
    }
  }, [watchSystemName])

  const toggleOpenScriptValueDialog = useCallback(() => {
    setIsShowScriptDialog(prevState => !prevState)
  }, [])

  const handleErrorLinks = () => {
    setError(`link.axisX.field.pathStr`, { type: 'custom', message: '' })
    setError(`link.axisY.field.pathStr`, { type: 'custom', message: '' })
  }

  const handleCorrectLinks = () => {
    clearErrors(`link.axisX.field.pathStr`)
    clearErrors(`link.axisY.field.pathStr`)
  }

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const selectedDates = useMemo(() => {
    return (
      [watchDuration?.field, watchDatetimeStart?.field, watchDatetimeEnd?.field] as ConfigField[]
    )
      .map(field => field?.pathStr)
      .filter(item => item)
  }, [
    watchDatetimeEnd?.field?.pathStr,
    watchDatetimeStart?.field?.pathStr,
    watchDuration?.field?.pathStr,
  ])

  const fieldPicker = useMemo(() => {
    return {
      existingPaths,
    }
  }, [existingPaths])

  const fieldPickerDate = useMemo(() => {
    return {
      existingPaths,
      selectedPaths: selectedDates,
    }
  }, [existingPaths, selectedDates])

  const handleClearDateFieldsError = useCallback(
    (value: ConfigField) => {
      const filledDatesCount = sum([
        Boolean(watchDatetimeStart?.field?.pathStr),
        Boolean(watchDatetimeEnd?.field?.pathStr),
        Boolean(watchDuration?.field?.pathStr),
      ])

      if (filledDatesCount >= 2) {
        clearErrors('datetimeStart')
        clearErrors('datetimeEnd')
        clearErrors('duration')
      }
    },
    [
      watchDatetimeStart?.field?.pathStr,
      watchDatetimeEnd?.field?.pathStr,
      watchDuration?.field?.pathStr,
    ]
  )

  return (
    <Box p={2}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label={t('ganttCreate.timelineForm.segmentSteps.data')} />
          <Tab label={t('ganttCreate.timelineForm.segmentSteps.view')} />
          <Tab label={t('ganttCreate.timelineForm.segmentSteps.edgeLabels')} />
        </Tabs>
      </Box>
      <TabPanel index={0} sx={{ p: 1 }} value={currentTab}>
        <FormInput
          disabled={modalType === 'edit'}
          inputType={GENERATOR_INPUT_TYPE.INPUT}
          label={t('ganttCreate.timelineForm.systemName')}
          name={`link.systemName`}
          placeholder={t('ganttCreate.timelineForm.systemName')}
          replacePattern={REGEX.MODEL_CODE_REPLACE_PATTERN}
          rules={{
            required: true,
            validate: value => value.trim().length !== 0,
          }}
        />
        <Box component='fieldset' mb={1}>
          <legend>{t('ganttCreate.objectLinks')}</legend>
          <GanttSegmentLinksHelper
            linkAxisX={watchLinkAxisX}
            linkAxisY={watchLinkAxisY}
            onCorrectLinks={handleCorrectLinks}
            onErrorLinks={handleErrorLinks}
          />
          <BindTypeInputs
            bindTypeOptions={[]}
            containerName='link.axisY'
            fieldPicker={fieldPicker}
            isDisabled={!watchedObjectResource}
            optionsFilter={keyFieldOptionsFilter}
            watchedObject={watchedObjectResource}
            blockLabel={t('ganttCreate.timelineForm.segmentAxisYKey', {
              label: watchedObjectResource?.label,
              id: watchedObjectResource?.id,
            })}
          />
          <div style={{ width: 50 }} />
          <BindTypeInputs
            bindTypeOptions={[]}
            containerName='link.axisX'
            fieldPicker={fieldPicker}
            isDisabled={isNoBarObject}
            optionsFilter={keyFieldOptionsFilter}
            watchedObject={currentBarObject}
            blockLabel={t('ganttCreate.timelineForm.segmentAxisXKey', {
              label: currentBarObject?.label,
              id: currentBarObject?.id,
            })}
          />
          <div hidden>
            <FormInput
              disabled
              readOnly
              inputType={GENERATOR_INPUT_TYPE.INPUT}
              label={t('ganttCreate.resourceField')}
              name={`link.resourceKeyField`}
            />
          </div>
        </Box>
        <BindTypeInputs
          isFieldsetMarkup
          bindTypeOptions={[]}
          containerName='datetimeStart'
          fieldPicker={fieldPickerDate}
          fieldsetTitle={t('ganttCreate.timelineForm.dateTimeStart')}
          isDisabled={isNoBarObject}
          optionsFilter={dateTimeFieldOptionsFilter}
          prefix={`bar_${currentBarIndex}_segment_${watchSystemName}_${SegmentFieldType.DATETIME_START}`}
          watchedObject={currentBarObject}
          onSaveField={handleClearDateFieldsError}
        />
        <BindTypeInputs
          isFieldsetMarkup
          bindTypeOptions={[]}
          containerName='datetimeEnd'
          fieldPicker={fieldPickerDate}
          fieldsetTitle={t('ganttCreate.timelineForm.dateTimeEnd')}
          isDisabled={isNoBarObject}
          optionsFilter={dateTimeFieldOptionsFilter}
          prefix={`bar_${currentBarIndex}_segment_${watchSystemName}_${SegmentFieldType.DATETIME_END}`}
          watchedObject={currentBarObject}
          onSaveField={handleClearDateFieldsError}
        />
        <BindTypeInputs
          isFieldsetMarkup
          bindTypeOptions={[]}
          containerName='duration'
          fieldPicker={fieldPickerDate}
          fieldsetTitle={t('ganttCreate.timelineForm.duration')}
          isDisabled={isNoBarObject}
          optionsFilter={durationFieldOptionsFilter}
          watchedObject={currentBarObject}
          onSaveField={handleClearDateFieldsError}
        />
      </TabPanel>
      <TabPanel index={1} sx={{ p: 1 }} value={currentTab}>
        <BaseFormFields
          currentBarKey={`bar_${currentBarIndex}`}
          disabled={isNoBarObject}
          existingPaths={existingPaths}
          isShowScriptDialog={isShowScriptDialog}
          keyValue={`segment_${watchSystemName}`}
          toggleOpenScriptValueDialog={toggleOpenScriptValueDialog}
          watchedObject={currentBarObject}
        />
      </TabPanel>
      <TabPanel index={2} sx={{ p: 1 }} value={currentTab}>
        <SegmentLabelsFields existingPaths={existingPaths} watchedObject={currentBarObject} />
      </TabPanel>
    </Box>
  )
}
