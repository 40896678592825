import { ModalType } from '@types'

type IsDuplicateErrorProps = {
  itemsList: {
    [key: string]: any
  }[]
  value: string
  modalType: ModalType
}

export const getIsDuplicateError = ({ itemsList, value, modalType }: IsDuplicateErrorProps) => {
  const foundDuplicates = itemsList.filter(item => (item?.code || item.id) === value)

  return foundDuplicates.length >= 1 && modalType !== 'edit'
}
