import { FIELD_VALUE_TYPE, OBJECT_FIELD_TYPE } from '@constants'
import { FormRow, ObjectDataRecord } from '@types'

const getObjectLinkValue = (
  value: any,
  valueName: string,
  objectValue: string,
  row: ObjectDataRecord,
  isArray: boolean
) => {
  // У массивов другая обработка
  if (isArray) {
    const enrichedValue = row[`${valueName}.${objectValue}`]

    return value ? { id: value, label: enrichedValue[value] } : null
  }

  return value ? { id: value, label: row[`${valueName}.${objectValue}`] } : null
}

// Формирует значение по-умолчанию в зависимости от типа филда
export const getDefaultValueByType = (field: FormRow, row: ObjectDataRecord) => {
  const isArray = field.type === OBJECT_FIELD_TYPE.ARRAY

  const valueType = field.valueType
  const valueName = field.code
  const objectValue = field.objectValue || ''
  // Не array оборачиваем в массив для унификации обработки (см. ниже)
  const originalValue = isArray ? ((row[valueName] || []) as any[]) : [row[valueName]]

  const resultValue = originalValue.map(value => {
    switch (valueType) {
      case FIELD_VALUE_TYPE.DATETIME:
        return value ?? null

      case FIELD_VALUE_TYPE.OBJ_PK_LINK:
      case FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK:
        return getObjectLinkValue(value, valueName, objectValue, row, isArray)

      default:
        return value ?? ''
    }
  })

  return [valueName, isArray ? resultValue : resultValue[0]]
}
