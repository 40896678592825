import { CSSProperties, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import JsxParser from 'react-jsx-parser'
import Editor from 'react-simple-code-editor'
import { highlight, languages, TokenObject } from 'prismjs'
import { Box, SxProps, Typography } from '@mui/material'

import { checkValidJS, ErrorValidJSType, generateDefaultObjectByFields } from '@helpers'
import { MAX_INPUT_LENGTH } from '@constants'
import { ObjectFieldDTO } from '@types'

import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-json'

import 'prismjs/themes/prism.css'

type EditorBaseProps = {
  language: 'js' | 'json' | 'jsx'
  value: string | undefined
  placeholder?: string
  // style?: (invalid: boolean) => CSSProperties | undefined
  style?: CSSProperties | undefined
  noValidate?: boolean
  isJsx?: boolean
  invalid?: boolean
  objectFields?: ObjectFieldDTO[]
  validator?: (value: string) => ErrorValidJSType | undefined
  onChange?: (value: string) => void
  onKeyUp?: (event: KeyboardEvent) => void
  onKeyDown?: (event: KeyboardEvent) => void
  onInvalid?: (state: boolean) => void
  boxSx?: SxProps
  autoFocus?: boolean
  customKeyWord?: TokenObject
  maxInputLength?: number
}

export const EditorBase = ({
  language,
  value,
  placeholder,
  style,
  noValidate = false,
  isJsx,
  objectFields,
  invalid = false,
  validator,
  onChange,
  onKeyUp,
  onKeyDown,
  onInvalid,
  boxSx,
  autoFocus = true,
  customKeyWord,
  maxInputLength = MAX_INPUT_LENGTH,
}: EditorBaseProps) => {
  const { t } = useTranslation()

  const srcObj = generateDefaultObjectByFields(objectFields || [])

  const [valueJs, setValueJs] = useState(value || '')
  const [errorMessage, setErrorMessage] = useState('')

  const setError = useCallback((validateObj: ErrorValidJSType): void => {
    setErrorMessage(validateObj.error ? (validateObj.message as string) : '')
    onInvalid?.(validateObj.error)
  }, [])

  const validateValue = (value: string): void => {
    if (noValidate) {
      setError({ error: false })

      return
    }

    if (validator) {
      const validatedObj = validator(value)

      if (validatedObj?.error) {
        setError(validatedObj)

        return
      }

      setError({ error: false })

      return
    }

    const validatedObj = checkValidJS(value, { srcObj })

    if (customKeyWord && customKeyWord.alias && validatedObj.message) {
      if (validatedObj.message.includes(customKeyWord.alias as string)) {
        setError({ error: false, message: null })

        return
      }
    }

    if (!isJsx && validatedObj.error) {
      setError(validatedObj)

      return
    }

    setError({ error: false })
  }

  const handleError = useCallback((error: Error) => {
    if (error) {
      setError({ error: true, message: `${t('error.valueJS')}` })

      return
    }

    setError({ error: false })
  }, [])

  const handleScriptValueChange = useCallback(
    (value: string) => {
      if (!value) {
        setError({ error: false })
      }

      validateValue(value)
      setValueJs(value)
      onChange?.(value)
    },
    [onChange]
  )

  const handleKeyUp = (event: KeyboardEvent) => onKeyUp?.(event)

  const handleKeyDown = (event: KeyboardEvent) => onKeyDown?.(event)

  const highlightCode = (code: string) => {
    if (customKeyWord) {
      return highlight(
        code,
        {
          ...languages[language],
          keyword: [...languages[language].keyword, customKeyWord],
        },
        language
      )
    }

    return highlight(code, languages[language], language)
  }

  useEffect(() => {
    if (value && value !== valueJs) {
      validateValue(value)
      setValueJs(value)
    }
  }, [value])

  return (
    <Box sx={boxSx}>
      <Editor
        autoFocus={autoFocus}
        highlight={highlightCode}
        maxLength={maxInputLength}
        padding={10}
        placeholder={placeholder}
        style={style}
        value={valueJs}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onValueChange={handleScriptValueChange}
      />
      <Box hidden>
        {isJsx && (
          <JsxParser showWarnings bindings={{ srcObj }} jsx={valueJs} onError={handleError} />
        )}
      </Box>
      {invalid && !errorMessage && <Typography color={'error'}>{t('error.required')}</Typography>}
      {errorMessage && <Typography color={'error'}>{errorMessage}</Typography>}
    </Box>
  )
}
