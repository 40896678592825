import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from '@microservices/wiskey-react-components'
import { Add as AddIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { FIELD_VALUE_TYPE } from '@constants'

import { FieldValueType } from '../../helpers/getDefaultValue'

import { ValueInputs } from './ValueInputs'

type FieldValueFormInputsProps = {
  type: FIELD_VALUE_TYPE | null
}

export const FieldValueFormInputs = ({ type }: FieldValueFormInputsProps) => {
  const { control } = useFormContext<unknown & { fieldValues: FieldValueType[] }>()

  const {
    fields: fieldValuesArrayInputs,
    append,
    remove,
  } = useFieldArray<unknown & { fieldValues: FieldValueType[] }>({
    control,
    name: 'fieldValues',
  })
  const { t } = useTranslation()

  const handleAddValue = () => {
    append({ label: '', value: '' })
  }

  const handleRemoveValue = (index: number) => {
    remove(index)
  }

  return (
    <Grid mb={2} mt={1}>
      {fieldValuesArrayInputs.map((field, index) => (
        <ValueInputs key={field.id} index={index} type={type} onRemove={handleRemoveValue} />
      ))}
      <Grid container>
        <Grid item>
          <Button startIcon={<AddIcon />} sx={{ mt: 0.5 }} onClick={handleAddValue}>
            {t('searchFilterForm.value.addButtonTitle')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
