import sum from 'lodash/sum'
import { AnyObject, TestContext } from 'yup'

import { SectionType } from '@gantt/components/GanttCreateOrEdit'

export function validateSegmentDates(val: string | undefined, ctx: TestContext<AnyObject>) {
  const segmentItem = ctx.from?.[2]
  const segment = segmentItem?.value as SectionType

  const filledDatesCount = sum([
    Boolean(segment.datetimeStart.field?.pathStr),
    Boolean(segment.datetimeEnd.field?.pathStr),
    Boolean(segment.duration?.field?.pathStr),
  ])

  if (filledDatesCount >= 2) {
    return true
  }

  return !(filledDatesCount < 2 && !val)
}
