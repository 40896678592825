import { useContext, useEffect } from 'react'
import { UseFormReset } from 'react-hook-form'

import { ModalType } from '@types'

import { PageContext, SectionType } from '@gantt/components/GanttCreateOrEdit'
import { getIsDuplicateError } from '@gantt/helpers'

type UseAddGanttSegmentDialogProps = {
  reset: UseFormReset<SectionType>
  onDuplicateError: (error: boolean) => void
  modalType: ModalType
}

export const useAddGanttSegmentDialog = ({
  reset,
  onDuplicateError,
  modalType,
}: UseAddGanttSegmentDialogProps) => {
  const { onSetCurrentSegment, currentRow, currentSectionsList } = useContext(PageContext)

  useEffect(() => {
    if (currentRow) {
      reset(currentRow)
    }
  }, [currentRow])

  const handleSave = (data: SectionType) => {
    const { title, tooltip, duration, datetimeStart, datetimeEnd, background, link, labels } = data

    const newData = {
      id: link.systemName,
      title,
      tooltip,
      duration,
      datetimeStart,
      datetimeEnd,
      background,
      link,
      labels,
    }

    const isDuplicateError = getIsDuplicateError({
      itemsList: currentSectionsList,
      value: newData.id,
      modalType,
    })

    onDuplicateError(isDuplicateError)

    !isDuplicateError && onSetCurrentSegment(newData)
  }

  return {
    handleSave,
  }
}
