import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { EmbeddedInput } from '@components/hookFormControllers/FieldPickerController/EmbeddedInput'

import { FIELD_VALUE_TYPE } from '@constants'
import { AutocompleteOption, GETObjectModel } from '@types'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit'
import { OptionsFilter } from '@gantt/types'

type FieldPickerControllerProps = {
  isEdit: boolean
  isFlatOptions?: boolean
  name: any
  watchedObject?: AutocompleteOption<string> | null
  optionsFilter?: OptionsFilter
  onChange?: (object: GETObjectModel) => void
  onInit?: (object: GETObjectModel) => void
  existingPaths?: string[]
  selectedPaths?: string[]
}

export const FieldPickerController = ({
  isEdit,
  isFlatOptions,
  name,
  watchedObject,
  optionsFilter,
  onChange,
  onInit,
  existingPaths,
  selectedPaths,
}: FieldPickerControllerProps) => {
  const { control, watch, setValue } = useFormContext<GanttFormValues>()
  const { fields, append } = useFieldArray({ control, name })
  const watchedEmbeddedObjects = watch(name)
  const objectCode = watchedObject?.id || watchedObject

  useEffect(() => {
    if (!isEdit && !watchedEmbeddedObjects?.at(0)?.objectCode) {
      setValue(name, [{ objectCode, refValueType: FIELD_VALUE_TYPE.OBJ_PK_LINK, field: '' }])
    }
    if (watchedEmbeddedObjects && watchedEmbeddedObjects?.at(0)?.objectCode !== objectCode) {
      setValue(name, [{ objectCode, refValueType: FIELD_VALUE_TYPE.OBJ_PK_LINK, field: '' }])
    }
  }, [objectCode])

  return (
    <>
      {fields.map((field, index) => (
        <EmbeddedInput
          key={field.id}
          append={append}
          embeddedObjectPickerControllerName={name}
          existingPaths={existingPaths}
          index={index}
          isFlatOptions={isFlatOptions}
          name={`${name}.${index}`}
          optionsFilter={optionsFilter}
          selectedPaths={selectedPaths}
          setValue={setValue}
          watchedEmbeddedObjects={watchedEmbeddedObjects}
          onChange={onChange}
          onInit={onInit}
        />
      ))}
    </>
  )
}
