import { memo, MouseEvent, MouseEventHandler, ReactNode, useCallback, useMemo } from 'react'
import { Divider, ListItemButton, ListItemText } from '@mui/material'

import { executeCode } from '@helpers'
import { MENU_POINT_TYPES } from '@constants'

import { getJsTitleItem } from './helpers/getJsTitleItem'
import { ActionData, ContextMenuItem, MetaData } from '.'

export const selectorMenuContent = 'menuContent' + Date.now()

type OptionProps = ContextMenuItem & {
  elementId?: string
  children?: ReactNode
  dataTransfer: (Omit<ActionData, 'metaData'> & { metaData?: MetaData }) | null
  onClick: (event: MouseEvent<HTMLDivElement>) => void
  onMouseEnter?: (e: MouseEvent<HTMLDivElement>) => void
}

const ItemMenu = (props: OptionProps) => {
  const {
    title,
    childrenMenu,
    children,
    menuPointType,
    jsTitle,
    dataTransfer,
    titleJsValue,
    conditionsDisabled,
    conditionsHidden,
    onClick,
    elementId,
  } = props

  const isHeader = menuPointType === MENU_POINT_TYPES.HEADER
  const isDivider = menuPointType === MENU_POINT_TYPES.DIVIDER

  const disabled = useMemo(
    () =>
      conditionsDisabled
        ? Boolean(executeCode(conditionsDisabled, { srcObj: dataTransfer?.metaData?.srcObj || {} }))
        : false,
    [conditionsDisabled, dataTransfer?.metaData?.srcObj]
  )

  const hidden = useMemo(
    () =>
      conditionsHidden
        ? Boolean(executeCode(conditionsHidden, { srcObj: dataTransfer?.metaData?.srcObj || {} }))
        : false,
    [conditionsHidden, dataTransfer?.metaData?.srcObj]
  )

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(event => {
    if (disabled) {
      return
    }

    if (!childrenMenu?.length && !isDivider) {
      onClick(event)
    }
  }, [])

  const getTitle = useMemo(
    () => getJsTitleItem({ title, jsTitle, titleJsValue, data: dataTransfer?.metaData }),
    [jsTitle, titleJsValue, title, dataTransfer?.metaData]
  )

  if (hidden) {
    return null
  }

  if (isDivider) {
    return <Divider sx={{ ml: '40px', borderColor: 'black' }} />
  }

  return (
    <ListItemButton
      disabled={disabled}
      hidden={hidden}
      id={elementId}
      sx={{
        ...(isHeader && { pl: '15px !important' }),
      }}
      onClick={handleClick}
    >
      <ListItemText sx={{ m: 0, pointerEvents: 'none' }} title={getTitle}>
        {getTitle}
      </ListItemText>
      {children}
    </ListItemButton>
  )
}

export default memo(ItemMenu)
