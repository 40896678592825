import React from 'react'
import { DoneAll } from '@mui/icons-material'
import { Typography } from '@mui/material'

import { SelectOption } from '@types'

import { GanttActionType } from '@gantt/components/GanttCreateOrEdit'
import { isCellTarget, isSegmentTarget } from '@gantt/helpers/helpers'

export const getSelectOptionDisabledTarget = (
  option: SelectOption,
  list: GanttActionType[],
  id?: string | number,
  actionCode?: string,
  eventCode?: string
) => {
  return Boolean(
    list.find(
      event =>
        event.target === option.id &&
        event.id !== id &&
        event.actionCode === actionCode &&
        event.eventCode === eventCode &&
        (!isCellTarget(event.eventCode) || !isSegmentTarget(event.eventCode))
    )
  )
}

export const getSelectOptionDisabledEvent = (
  option: SelectOption,
  list: GanttActionType[],
  id?: string | number
) => {
  return Boolean(
    list.find(
      event =>
        event.eventCode === option.name &&
        event.id !== id &&
        !isCellTarget(event.eventCode) &&
        !isSegmentTarget(event.eventCode)
    )
  )
}

export const GanttActionOptionSelectTarget = (props: {
  option: SelectOption
  list: GanttActionType[]
}) => {
  const { option, list } = props
  const isAlreadyUsed = list.find(
    event =>
      event.target === option.id &&
      (isCellTarget(event.eventCode) || isSegmentTarget(event.eventCode))
  )

  if (isAlreadyUsed) {
    return (
      <>
        <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
        <Typography ml={1}> {option.name}</Typography>
      </>
    )
  }

  return <>{option.name}</>
}

export const GanttActionOptionSelectEvent = (props: {
  option: SelectOption
  list: GanttActionType[]
}) => {
  const { list, option } = props
  const isAlreadyUsed = list.find(
    event =>
      event.eventCode === option.name &&
      !isCellTarget(event.eventCode) &&
      !isSegmentTarget(event.eventCode)
  )

  if (isAlreadyUsed) {
    return (
      <>
        <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
        <Typography ml={1}> {option.name}</Typography>
      </>
    )
  }

  return <>{option.name}</>
}
