import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { Close as CloseIcon } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import { useFetchAllContextQuery, useFetchContextControlPanelByIdNoCacheMutation } from '@redux/api'
import { setContext } from '@redux/reducers/contexts.reducer'

import { useAppDispatch, useAuth } from '@hooks'
import { buttonStylesByMode, getLocalStorageContext } from '@helpers'
import { GENERATOR_INPUT_TYPE, USER_ROLES } from '@constants'
import { AutocompleteOption } from '@types'

type ContextForm = {
  context: AutocompleteOption | null
}

export const ChooseContext = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { username, doLogout, hasRole } = useAuth()

  const prevContext = getLocalStorageContext()

  const [, { error: errorContext }] = useFetchContextControlPanelByIdNoCacheMutation({
    fixedCacheKey: 'initial-context',
  })
  const { data: contexts, isFetching: isLoadingContexts } = useFetchAllContextQuery(
    {},
    { refetchOnMountOrArgChange: true }
  )

  const methods = useForm<ContextForm>({
    defaultValues: { context: null },
  })

  const {
    reset,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isDirty },
  } = methods

  useEffect(() => {
    reset({ context: prevContext })
  }, [])

  const contextOptions = useMemo(
    () => contexts?.map(({ id, title: label }) => ({ id, label })) || [],
    [contexts]
  )

  const skipChoose = useMemo(
    () => contextOptions.length === 0 && hasRole([USER_ROLES.ADMIN]),
    [contextOptions, hasRole]
  )

  const handleClose = (): void => {
    const notFoundContext = (errorContext as FetchBaseQueryError)?.status === 404

    if (!prevContext && contextOptions) {
      setError('context', { type: 'custom', message: '' })

      return
    }

    if ((!prevContext || notFoundContext) && !hasRole([USER_ROLES.ADMIN])) {
      doLogout()

      return
    }

    navigate('/')
  }

  const handleSaveContext = ({ context }: ContextForm) => {
    if (context) {
      dispatch(setContext({ id: context.id }))

      localStorage.setItem('context', JSON.stringify(context))
      navigate('/')

      return
    }

    if (skipChoose) {
      navigate('/')
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pt: '15%',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography position='relative' textAlign='center' variant='h4'>
          Welcome, {username ? username : 'User'}! <br />
          Choose the Context
          <Box sx={{ position: 'absolute', top: '-60px', right: '-120px' }}>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize='large' />
            </IconButton>
          </Box>
        </Typography>
      </Box>
      <Box>
        <FormProvider {...methods}>
          <FormInput
            autocompleteOptions={contextOptions}
            disabled={skipChoose}
            inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
            loading={isLoadingContexts}
            name={'context'}
            rules={{ required: !skipChoose }}
            sx={{ width: 300, pt: 3 }}
            placeholder={
              skipChoose
                ? t('contexts.placeholder.emptyContext')
                : t('contexts.placeholder.selectContext')
            }
            onChangeAutocomplete={value => clearErrors()}
          />
        </FormProvider>
      </Box>
      <Box>
        <Button
          disabled={skipChoose ? false : !isDirty}
          sx={theme => ({
            ...buttonStylesByMode(theme),
            mt: 3,
          })}
          onClick={handleSubmit(handleSaveContext)}
        >
          <Typography>
            {skipChoose ? t('contexts.continueWithoutContext') : t('contexts.continue')}
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}
