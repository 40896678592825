import React from 'react'
import i18next from 'i18next'
import { DoneAll } from '@mui/icons-material'
import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined'
import { Typography } from '@mui/material'

import { SelectOption } from '@types'

type RenderPickerOptionSelectProps = {
  option: unknown
  currentValue: string
  currentPath: string
  embeddedObjectsLength: number
  existingPaths: string[]
  selectedPaths: string[]
}

export const PickerOptionSelect = ({
  option,
  currentValue,
  currentPath,
  embeddedObjectsLength,
  existingPaths,
  selectedPaths,
}: RenderPickerOptionSelectProps) => {
  let path = ''
  const selectOption = option as SelectOption

  if (currentValue) {
    const newPathArr = currentPath.split('.')

    newPathArr[newPathArr.length - 1] = selectOption.name

    path = newPathArr.join('.')
  } else {
    path = embeddedObjectsLength > 1 ? `${currentPath}.${selectOption.name}` : selectOption.name
  }

  const isAlreadyUsed = existingPaths.includes(path)
  const isAlreadySelected = selectedPaths.includes(path)

  return isAlreadyUsed || isAlreadySelected ? (
    <>
      {isAlreadySelected && (
        <DoneAll
          sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }}
          titleAccess={i18next.t('ganttCreate.fieldsSelectedInContext')}
        />
      )}
      {!isAlreadySelected && (
        <WhereToVoteOutlinedIcon
          sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }}
          titleAccess={i18next.t('ganttCreate.fieldInvolvedInConfiguration')}
        />
      )}
      <Typography ml={1}> {selectOption.name}</Typography>
    </>
  ) : (
    <>{selectOption.name}</>
  )
}
