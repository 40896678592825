import isObject from 'lodash/isObject'

export const renderObjectEmbeddedCell = (
  code: string,
  // objectValue: string,
  srcObj: Record<string, any>
) => {
  // const linkedObject = srcObj[`${code}_enriched`] || {}
  // const valueByObjectValue = linkedObject[objectValue]

  // return valueByObjectValue || ''

  const result = srcObj[code] || ''

  const isObjectValue = isObject(result)

  return isObjectValue ? Object.values(result).join(', ') : result
}
