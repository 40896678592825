import i18next from 'i18next'
import moment from 'moment'

export const renderJSValueCell = (
  // Содержимое самого скрипта
  value: string,
  scrObj: Record<string, unknown>
) => {
  try {
    const renderValue = new Function('srcObj', 'moment', value)
    const result = renderValue(scrObj, moment)

    return result
  } catch {
    return i18next.t('error.common')
  }
}
