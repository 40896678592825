import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  FieldValues,
  Path,
  UseFieldArrayAppend,
  useFormContext,
  UseFormSetValue,
} from 'react-hook-form'
import i18next from 'i18next'
import cloneDeep from 'lodash/cloneDeep'
import { FormInput, FormInputProps } from '@microservices/wiskey-react-components'

import { getPickerSelectOptionDisabled } from '@components/hookFormControllers/FieldPickerController/helpers/getPickerSelectOptionDisabled'
import { PickerOptionSelect } from '@components/hookFormControllers/FieldPickerController/helpers/PickerOptionSelect'

import { useFetchObjectByCodeQuery } from '@redux/api'

import { isObjectValueType } from '@helpers'
import { FIELD_VALUE_TYPE, GENERATOR_INPUT_TYPE } from '@constants'
import { GETObjectModel, ObjectFieldDTO } from '@types'

import { OptionsFilter } from '@gantt/types'

type EmbeddedObject = {
  objectCode: string
  valueType: FIELD_VALUE_TYPE
  refValueType: FIELD_VALUE_TYPE
  field?: string
  isPk?: boolean
  isInternalId?: boolean
  description?: string
}

type Props<T extends FieldValues> = {
  name: Path<T> // todo разобраться с типом
  append: UseFieldArrayAppend<EmbeddedObject, never>
  index: number
  setValue: UseFormSetValue<T>
  embeddedObjectPickerControllerName: any
  isFlatOptions?: boolean
  optionsFilter?: OptionsFilter
  onChange?: (object?: GETObjectModel) => void
  onInit?: (object?: GETObjectModel) => void
  watchedEmbeddedObjects: { field: string }[]
  existingPaths?: string[]
  selectedPaths?: string[]
}

export const EmbeddedInput = <T extends FieldValues>({
  name,
  append,
  index,
  setValue,
  embeddedObjectPickerControllerName,
  isFlatOptions,
  onChange,
  onInit,
  optionsFilter,
  watchedEmbeddedObjects,
  existingPaths = [],
  selectedPaths = [],
}: Props<T>): ReactElement => {
  const { watch } = useFormContext()
  const [isInit, setIsInit] = useState(false)
  const { objectCode, refValueType } = watch(name) as EmbeddedObject
  const watchedCurrentFieldValue = watch(`${name}.field`)
  const {
    data: object,
    isLoading,
    isSuccess,
  } = useFetchObjectByCodeQuery(objectCode, {
    skip: !objectCode,
  })

  useEffect(() => {
    if (isSuccess && !isInit) {
      setIsInit(true)
      onInit?.(object)
    }
  }, [isSuccess])

  const objectsList = watch(embeddedObjectPickerControllerName)

  const currentPath = watchedEmbeddedObjects
    .slice(0, index + 1)
    .map(obj => obj.field)
    .filter(item => item)
    .join('.')

  const getSelectOptionDisabled = useMemo<FormInputProps['getSelectOptionDisabled']>(
    () => option =>
      getPickerSelectOptionDisabled({
        option,
        currentValue: watchedCurrentFieldValue,
        currentPath,
        embeddedObjectsLength: watchedEmbeddedObjects.length,
        selectedPaths,
      }),
    [currentPath, selectedPaths, watchedCurrentFieldValue, watchedEmbeddedObjects.length]
  )

  const handleChangeSelect = (value: string | number) => {
    const isChangedRootElement = index !== objectsList.length - 1 || objectsList.length === 1
    const field = object?.fields.find((item: ObjectFieldDTO) => item.name === value)

    setValue(name, {
      ...watch(name),
      field: value,
      valueType: field?.valueType,
      isPk: field?.isPk,
      isInternalId: field?.isInternalId,
      description: field?.desc,
    })

    if (isObjectValueType(field?.valueType)) {
      append({
        objectCode: field?.model,
        refValueType: field?.valueType,
        field: null,
      })
    }

    if (isChangedRootElement) {
      const newList = cloneDeep(objectsList)?.filter(
        (item: EmbeddedObject, i: number) => i <= index
      )

      if (isObjectValueType(field?.valueType)) {
        newList.push({
          objectCode: field?.model || '',
          refValueType: field?.valueType,
          field: null,
        })
      }

      setValue(embeddedObjectPickerControllerName, newList)
    }

    onChange?.(object)
  }

  const selectOptions = useMemo(() => {
    let options: ObjectFieldDTO[] = structuredClone(object?.fields) || []

    if (isFlatOptions) {
      options = options?.filter((item: ObjectFieldDTO) => !isObjectValueType(item.valueType)) || []
    }

    if (optionsFilter) {
      options = optionsFilter(options) as ObjectFieldDTO[]
    }

    return options
  }, [object, isFlatOptions, refValueType])

  if (!objectCode) {
    return <></>
  }

  return (
    <FormInput
      getSelectOptionDisabled={getSelectOptionDisabled}
      inputType={GENERATOR_INPUT_TYPE.SELECT}
      label={objectCode}
      loading={isLoading}
      name={`${name}.field`}
      renderValueSelect={value => <>{value}</>}
      selectNoItemsMessage={i18next.t('ganttCreate.common.noItems')}
      selectOptions={selectOptions}
      renderOptionSelect={option => (
        <PickerOptionSelect
          currentPath={currentPath}
          currentValue={watchedCurrentFieldValue}
          embeddedObjectsLength={watchedEmbeddedObjects.length}
          existingPaths={existingPaths}
          option={option}
          selectedPaths={selectedPaths}
        />
      )}
      rules={{
        required: true,
        validate: value => (value.trim().length === 0 ? false : undefined),
      }}
      onChangeSelect={handleChangeSelect}
    />
  )
}
