import { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import isObject from 'lodash/isObject'
import { SelectOption } from '@microservices/wiskey-react-components'

import { useFetchAllFormQuery, useFetchDictionariesByCategoryCodeQuery } from '@redux/api'
import { useFetchContextMenuListQuery } from '@redux/api/contextMenu.api'
import { useFetchAllObjectQuery } from '@redux/api/objects.api'

import { usePrompt } from '@hooks'
import { getAvailableActionEvents } from '@helpers'
import { ACTION_CODE, CATEGORIES, EVENT_CODE, MODAL_TYPE } from '@constants'
import { AutocompleteOption, ViewRow } from '@types'

import { PageContext } from '../../../EntityCreateOrEdit'
import { getDefaultValue } from '../helpers'

import { ActionForm, useInputs } from './useInputs'

export const useAddActionDialog = ({ columns }: { columns: ViewRow[] }) => {
  const defaultValues: ActionForm = getDefaultValue()
  const methods = useForm<ActionForm>({ defaultValues })
  const [isLoading, setLoading] = useState(true)
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
    setValue,
  } = methods

  const watchJson = watch('json')
  const watchActionCode = watch('actionCode')
  const watchEvent = watch('eventCode')
  const watchFormObject = watch('formObject')

  const isOpenFormAction =
    watchActionCode === ACTION_CODE.OPEN_ANOTHER_FORM_MODEL ||
    watchActionCode === ACTION_CODE.OPEN_FORM_MODEL

  const isContextMenu = watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU

  usePrompt({ when: isDirty })

  const {
    data: bindingValuesActionEvents,
    isLoading: isLoadingBindingValuesEvents,
    isFetching: isFetchingBindingValuesEvents,
  } = useFetchDictionariesByCategoryCodeQuery(
    isContextMenu ? CATEGORIES.BINDING_EVENTS_ACTION_CONTEXT_MENU : CATEGORIES.BINDING_EVENTS_ACTION
  )

  const loadingBindingValuesEvents = isLoadingBindingValuesEvents || isFetchingBindingValuesEvents

  const {
    createAction,
    currentRow,
    currentEntity,
    onCloseModal,
    modalType,
    editAction,
    onSetModalProperty,
    entityCode,
    bindingValuesAction,
    actionTable,
    objectCode,
  } = useContext(PageContext)

  const viewType = currentEntity?.type

  const {
    data: forms,
    isFetching: isFetchingAllForm,
    isLoading: isLoadingAllForm,
  } = useFetchAllFormQuery(
    {
      objectCode: watchFormObject ? watchFormObject.id : '',
    },
    {
      skip: !watchFormObject,
    }
  )

  const { data: contextMenus } = useFetchContextMenuListQuery({ objectCode }, { skip: !objectCode })

  const { data: objects, isLoading: isAllObjectsLoading } = useFetchAllObjectQuery(undefined, {
    skip: !isOpenFormAction,
  })

  const loadingAllForm = isFetchingAllForm || isLoadingAllForm

  const contextMenusOptions = useMemo(
    () => contextMenus?.map(({ id, title: label }) => ({ id, label })) || [],
    [contextMenus]
  )

  const targetColumnsOptions = useMemo(
    () => columns.map(({ id, title: label }) => ({ id, label })) || [],
    [columns]
  )

  const formCodeOptions: AutocompleteOption[] = useMemo(
    () =>
      forms?.data.map(form => ({
        id: form.code,
        label: form.title,
      })) ?? [],
    [forms]
  )

  const filteredActionEvents = getAvailableActionEvents(
    bindingValuesActionEvents,
    actionTable,
    currentRow
  )

  const filteredTargetColumnsOptions = targetColumnsOptions.filter(
    targetColumn =>
      !actionTable?.rows.find(
        event => event.target?.id === targetColumn.id && currentRow?.target?.id !== targetColumn.id
      )
  )

  const actionTypeOptions: SelectOption[] = useMemo(
    () =>
      bindingValuesAction?.map(cat => ({
        id: cat.id,
        name: cat.code,
      })) || [],
    [bindingValuesAction]
  )

  const eventOptions: SelectOption[] = useMemo(
    () =>
      filteredActionEvents?.map(cat => ({
        id: cat.id,
        name: cat.code,
      })) || [],
    [filteredActionEvents]
  )

  const objectOptions = useMemo(() => {
    if (!objects) {
      return []
    }

    if (watchActionCode === ACTION_CODE.OPEN_FORM_MODEL) {
      const entityObject = objects.find(object => object.code === objectCode)

      if (!entityObject) {
        return []
      }

      return [{ id: entityObject.code, label: entityObject.title }]
    }

    return objects.map(object => {
      return {
        id: object.code,
        label: object.title,
      }
    })
  }, [objects, watchActionCode])

  useEffect(() => {
    if (currentRow) {
      reset({
        ...currentRow,
        params: currentRow.params ? JSON.stringify(currentRow.params) : '',
        order: currentRow.order?.toString(),
        formObject: currentRow?.objectCode
          ? {
              id: currentRow?.objectCode,
              label: currentRow?.objectTitle,
            }
          : null,
        objectFormCode: currentRow?.objectFormCode
          ? {
              id: currentRow?.objectFormCode,
              label: currentRow?.objectFormTitle,
            }
          : null,
        json: Boolean(currentRow.params),
        contextMenu: currentRow.contextMenu
          ? { id: currentRow.contextMenu.id, label: currentRow.contextMenu.title }
          : null,
        targetColumn: currentRow.target
          ? { id: currentRow.target.id, label: currentRow.target.title }
          : null,
        columnToSort: null,
      })

      setLoading(false)
    }
  }, [currentRow])

  useEffect(() => {
    onSetModalProperty?.('action')
  }, [])

  const handleSave = handleSubmit((data: ActionForm) => {
    const {
      id = Date.now(),
      code,
      title,
      actionCode = ACTION_CODE.OPEN_FORM_MODEL,
      eventCode = EVENT_CODE.ON_ROW_CLICK,
      params = '',
      formObject,
      objectFormCode,
      json,
      contextMenu,
      targetColumn,
    } = data

    if (modalType === MODAL_TYPE.EDIT && currentRow) {
      editAction?.({
        code,
        title,
        eventCode,
        actionCode,
        id,
        viewCode: entityCode,
        targetId: targetColumn?.id,
        contextMenuId: contextMenu?.id,
        viewType,
        ...(json
          ? { params }
          : {
              objectCode:
                isObject(formObject) && formObject?.id ? formObject.id.toString() : undefined,
              objectTitle:
                isObject(formObject) && formObject?.label ? formObject.label.toString() : undefined,
              objectFormCode:
                isObject(objectFormCode) && objectFormCode?.id
                  ? objectFormCode.id.toString()
                  : undefined,
            }),
      })
        .unwrap()
        .then(() => {
          onCloseModal()
        })
        .catch(console.error)

      return
    }
    createAction?.({
      code,
      title,
      eventCode,
      actionCode,
      id,
      targetId: targetColumn?.id,
      contextMenuId: contextMenu?.id,
      viewCode: entityCode,
      viewType,
      ...(json
        ? { params }
        : {
            objectCode:
              isObject(formObject) && formObject?.id ? formObject.id.toString() : undefined,
            objectTitle:
              isObject(formObject) && formObject?.label ? formObject.label.toString() : undefined,
            objectFormCode:
              isObject(objectFormCode) && objectFormCode?.id
                ? objectFormCode.id.toString()
                : undefined,
          }),
    })
      .unwrap()
      .then(() => {
        onCloseModal()
        reset(defaultValues)
      })
      .catch(console.error)
  })

  const actionInputs = useInputs({
    setValue,
    actionTypeOptions,
    eventOptions,
    objectOptions,
    watchJson,
    watchEvent,
    watchActionCode,
    watchFormObject,
    formCodeOptions,
    loadingAllForm,
    targetColumnsOptions: filteredTargetColumnsOptions,
    contextMenusOptions,
    loadingBindingValuesEvents,
    isAllObjectsLoading,
    isOpenFormAction,
  })

  return {
    isDirty,
    actionInputs,
    methods,
    isLoading,
    handleSave,
  }
}
