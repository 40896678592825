import last from 'lodash/last'

import { SelectOption } from '@types'

type GetPickerSelectOptionDisabledProps = {
  option: SelectOption
  currentValue: string
  currentPath: string
  embeddedObjectsLength: number
  selectedPaths: string[]
}

export const getPickerSelectOptionDisabled = ({
  option,
  currentValue,
  currentPath,
  embeddedObjectsLength,
  selectedPaths,
}: GetPickerSelectOptionDisabledProps) => {
  let path = ''
  const selectOption = option as SelectOption

  if (currentValue) {
    const newPathArr = currentPath.split('.')

    newPathArr[newPathArr.length - 1] = selectOption.name

    path = newPathArr.join('.')
  } else {
    path = embeddedObjectsLength > 1 ? `${currentPath}.${selectOption.name}` : selectOption.name
  }

  return selectedPaths.includes(path) && selectOption.name !== last(currentPath.split('.'))
}
