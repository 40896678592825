import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { ConfirmModal, FormInput } from '@microservices/wiskey-react-components'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import { Grid, IconButton } from '@mui/material'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { AutocompleteOption } from '@types'

import { ModalRefPathPicker } from '@gantt/components/GanttCreateOrEdit/components/ModalRefPathPicker'
import { ConfigField } from '@gantt/components/GanttCreateOrEdit/types'
import { useCopy } from '@gantt/hooks/useCopy'

export type Props = {
  hasCopyButton?: boolean
  field: Record<'id', string>
  index: number
  watchedObject: AutocompleteOption<string> | null
  isHiddenRemove?: boolean
  watchValue: ConfigField
  existingPaths: string[]
  selectedPaths: string[]
  controllerNameField: string
  controllerNamePathStr: string
  controllerNamePathArray: string
  controllerNameComment?: string
  onRemove: (index: number) => void
  onSaveModalRefPick?: () => void
}

export const VariableItem = ({
  hasCopyButton,
  field,
  index,
  watchedObject,
  onRemove,
  onSaveModalRefPick,
  watchValue,
  existingPaths,
  selectedPaths,
  controllerNameField,
  controllerNamePathStr,
  controllerNamePathArray,
  controllerNameComment,
  isHiddenRemove = false,
}: Props) => {
  const { t } = useTranslation()
  const [initialValue, setInitialValue] = useState<ConfigField>()
  const hasComment = Boolean(controllerNameComment)
  const handleCopyVariable = useCopy()
  const [isShowConfirmRemoveDialog, setIsShowConfirmRemoveDialog] = useState(false)

  useEffect(() => {
    if (!initialValue?.pathStr) {
      const clonedValue = structuredClone(watchValue)
      setInitialValue(clonedValue)
    }

    return () => setInitialValue(undefined)
  }, [watchValue?.pathStr])

  const handleRemoveItem = (index: number) => {
    onRemove(index)
  }

  const handleShowConfirmRemoveDialog = (show: boolean) => {
    setIsShowConfirmRemoveDialog(show)
  }

  return (
    <>
      {isShowConfirmRemoveDialog && (
        <ConfirmModal
          actionBtnText={t('modal.deleteVariable.btn')}
          containerStyle={{ px: 2.5, py: 2.5, borderRadius: 0 }}
          isShow={isShowConfirmRemoveDialog}
          text={t('modal.deleteVariable.warning', { variable: watchValue.field })}
          title={t('modal.deleteVariable.title')}
          onClose={() => handleShowConfirmRemoveDialog(false)}
          onConfirm={() => handleRemoveItem(index)}
        />
      )}
      <Grid key={field.id} container rowSpacing={1} xs={12}>
        <Grid item pr={4} xs={hasComment ? 3.5 : 5.5}>
          <FormInput
            inputType={GENERATOR_INPUT_TYPE.INPUT}
            name={controllerNameField}
            placeholder={i18next.t('placeholder.variable')}
          />
        </Grid>
        <Grid item pr={4} xs={hasComment ? 4 : 6}>
          <ModalRefPathPicker
            hasClearInInput
            currentValue={initialValue}
            embeddedObjectPickerControllerName={controllerNamePathArray}
            existingPaths={existingPaths}
            isDisabled={!watchedObject}
            name={controllerNamePathStr}
            selectedPaths={selectedPaths}
            watchedObject={watchedObject}
            onSave={onSaveModalRefPick}
          />
        </Grid>
        {hasComment && controllerNameComment && (
          <Grid item flexGrow={1}>
            <FormInput
              inputType={GENERATOR_INPUT_TYPE.INPUT}
              name={controllerNameComment}
              placeholder={i18next.t('placeholder.comment')}
            />
          </Grid>
        )}
        <Grid alignItems='center' display='flex' justifyContent={'end'} pl={1}>
          {!isHiddenRemove && (
            <IconButton onClick={() => handleShowConfirmRemoveDialog(true)}>
              <DeleteIcon />
            </IconButton>
          )}
          {hasCopyButton && (
            <IconButton
              disabled={!watchValue.field}
              onClick={() => handleCopyVariable(watchValue.field)}
            >
              <ContentCopyIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </>
  )
}
