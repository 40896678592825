import { BindField, BindTypeField, GANTT_BIND_TYPE_LIST } from '@gantt/components/GanttCreateOrEdit'

export const getInvolvedPaths = (config: Record<string, any>) => {
  const data = structuredClone(config)

  const paths: string[] = []

  function getPaths(item: Record<string, any>) {
    for (const property in item) {
      if (property === 'link') {
        getPaths(item[property])
      }

      if (
        item[property]?.bindType === GANTT_BIND_TYPE_LIST.FIELD &&
        item[property]?.field?.pathStr
      ) {
        paths.push(item[property]?.field?.pathStr)
      } else if (item[property]?.bindType === GANTT_BIND_TYPE_LIST.FIELD_ARRAY) {
        item[property].field_array = item[property]?.field_array?.filter(
          (item: BindTypeField) => item.field
        )
      } else {
        if (Array.isArray(item[property])) {
          item[property].map((item: BindField) => {
            getPaths(item)
          })
        }
      }
    }
  }

  getPaths(data)

  return paths
}
