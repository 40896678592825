import isInteger from 'lodash/isInteger'

type GetNewLastIndexByDataProps = {
  items: {
    [key: string]: unknown
  }[]
  getterNumber: (value: { [key: string]: any }) => any
}
export const getNewLastIndexByData = ({ items, getterNumber }: GetNewLastIndexByDataProps) => {
  const getter = (value: any) => Number(getterNumber(value))
  const ids = items?.map(getter).filter(isInteger) || []
  const index = Math.max(0, ...ids) + 1

  return ids.includes(index) ? undefined : index
}
