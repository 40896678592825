import { useTranslation } from 'react-i18next'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { FormHelperText, Typography } from '@mui/material'

import { AutocompleteOption, AXIS_TYPE } from '@gantt/components/GanttCreateOrEdit'

type GanttPreFilterHelperProps = {
  axis: AXIS_TYPE
  preFilterValue: string
  appearsIn: AXIS_TYPE
  object: AutocompleteOption<string> | null
  mainObject: string
}

export const GanttPreFilterHelper = ({
  axis,
  preFilterValue,
  appearsIn,
  object,
  mainObject,
}: GanttPreFilterHelperProps) => {
  const { t } = useTranslation()

  if (!preFilterValue?.trim()) {
    return null
  }

  const correctMsg = t('ganttCreate.preFilterCorrect', {
    axis: appearsIn,
    object: object?.id,
    mainObject,
  })

  const incorrectMsg = t('ganttCreate.preFilterIncorrect', {
    axis: appearsIn,
    object: object?.id,
    mainObject,
  })

  return (
    <FormHelperText sx={{ display: 'flex', alignItems: 'end' }}>
      {appearsIn === axis ? (
        <>
          <CheckCircleIcon color={'success'} />
          <Typography sx={{ color: '#2e7d32' }} variant={'body2'}>
            {correctMsg}
          </Typography>
        </>
      ) : (
        <>
          <WarningAmberIcon color={'warning'} />
          <Typography sx={{ color: '#ed6c02' }} variant={'body2'}>
            {incorrectMsg}
          </Typography>
        </>
      )}
    </FormHelperText>
  )
}
