import i18next from 'i18next'

import { BIND_TYPE, FIELD_VALUE_TYPE } from '@constants'
import { AutocompleteOption, GETDropDownFilter } from '@types'

import { EmbeddedObject } from '@gantt/components/GanttCreateOrEdit'

export type FieldForm = {
  id?: number | string
  code: string
  bindType?: BIND_TYPE
  title: string
  value: string
  valueType: FIELD_VALUE_TYPE | null
  objectValue?: string
  objectFormCode?: AutocompleteOption | string | null
  embeddedObjectCode?: string
  objectFormTitle?: string
  required: boolean
  editField: boolean
  params?: string
  json?: boolean
  dropDownListCode?: AutocompleteOption | string | null
  dropDownWindowCode?: AutocompleteOption | string | null
  dropDownListFilters: GETDropDownFilter[]
  dropDownWindowFilters: GETDropDownFilter[]
  isDefaultPlaceholder: boolean
  placeholderValue: string
  formatDate: AutocompleteOption | null
  asCheckbox: boolean
  asDuration: boolean
  preFillSourceObject?: AutocompleteOption<string> | null
  preFillLink?: EmbeddedObject[] | null
  isMultiline?: boolean
  isAddFieldValues: boolean
  fieldValues: FieldValueType[]
}

export type FieldValueType = {
  label: string
  value: string | number
}

export const getDefaultValue = (): FieldForm => {
  return {
    code: '',
    bindType: i18next.t('placeholder.bindType'),
    title: '',
    value: i18next.t('placeholder.value'),
    valueType: null,
    objectValue: i18next.t('placeholder.objectValue'),
    objectFormCode: null,
    required: false,
    editField: true,
    params: '',
    json: false,
    dropDownListCode: null,
    dropDownWindowCode: null,
    dropDownListFilters: [],
    dropDownWindowFilters: [],
    isDefaultPlaceholder: true,
    placeholderValue: '',
    formatDate: null,
    asCheckbox: false,
    asDuration: false,
    preFillSourceObject: null,
    preFillLink: [],
    isMultiline: false,
    isAddFieldValues: false,
    fieldValues: [],
  }
}
