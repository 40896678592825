import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { Clear } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { FIELD_VALUE_TYPE, GENERATOR_INPUT_TYPE } from '@constants'

import { FieldValueType } from '../../helpers/getDefaultValue'

export const ValueInputs = ({
  index,
  type,
  onRemove,
}: {
  index: number
  type: FIELD_VALUE_TYPE | null
  onRemove: (index: number) => void
}) => {
  const { t } = useTranslation()

  const { watch } = useFormContext<unknown & { fieldValues: FieldValueType[] }>()

  const valueInputName = `fieldValues.${index}.value`
  const nameInputName = `fieldValues.${index}.label`

  const handleRemove = () => onRemove(index)

  return (
    <Grid container columnSpacing={3} flexDirection={'row'} flexWrap={'nowrap'}>
      <Grid item width={'100%'}>
        <FormInput
          inputType={GENERATOR_INPUT_TYPE.INPUT}
          label={t('fieldForm.fieldValues.value.label')}
          name={valueInputName}
          placeholder={t('fieldForm.fieldValues.value.placeholder')}
          type={type === FIELD_VALUE_TYPE.INTEGER ? 'number' : undefined}
        />
      </Grid>
      <Grid item width={'100%'}>
        <FormInput
          inputType={GENERATOR_INPUT_TYPE.INPUT}
          label={t('fieldForm.fieldValues.text.label')}
          labelSx={{ minWidth: 50, width: 50 }}
          name={nameInputName}
          placeholder={t('fieldForm.fieldValues.text.placeholder')}
          rules={{
            validate: value => {
              return !watch('fieldValues').find(
                (field: FieldValueType, i: number) => field.label === value && index !== i
              )
            },
            required: true,
          }}
        />
      </Grid>
      <Grid item>
        <Button sx={{ minWidth: 40 }} onClick={handleRemove}>
          <Clear />
        </Button>
      </Grid>
    </Grid>
  )
}
