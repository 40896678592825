import { GANTT_BIND_TYPE_LIST, GanttColumnForm } from '@gantt/components/GanttCreateOrEdit'

export const getGanttColumnDefaultValue = (code: number): GanttColumnForm => {
  return {
    id: Date.now(),
    title: '',
    code: `column_${code}`,
    field: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: {
        field: '',
        pathStr: '',
        pathArray: [],
      },
    },
    valueType: null,
    pinning: false,
    pinnedColumn: null,
    useParameters: false,
    visibility: true,
    parameters: [],
  }
}
