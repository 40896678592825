import { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from 'react'
import { AutocompleteOption, LabelPlacement } from 'src/types'
import {
  AutoComplete,
  AutoCompleteProps,
  FormLabel,
  TextField,
} from '@microservices/wiskey-react-components'
import { Box, Grid, InputProps, SxProps, useTheme } from '@mui/material'

import { generalFieldStylesByMode, getAutoCompletePopperSxByDisplayMethod } from '@helpers'
import { FIELD_VALUE_TYPE, UPDATES_ROW_CLASSNAME } from '@constants'

export type SyntheticAutoCompleteProps<T> = {
  value?: AutocompleteOption | number | string
  onChange?: (details: AutocompleteOption) => void
  name: string
  objectCode: string
  valueType: FIELD_VALUE_TYPE
  inputProps?: InputProps
  onLoadEnum?: (options: AutocompleteOption[], value: any) => void
  label?: string
  labelPlacement?: LabelPlacement
  labelSx?: SxProps
  inputSx?: SxProps
  invalid?: boolean
  dirtyFields?: Partial<Readonly<{ [x: string]: boolean }>>
  isDialogWindow?: boolean
  popupIcon?: React.ReactNode
  smallPopupIconPadding?: boolean
} & Omit<AutoCompleteProps<T>, 'renderInput'>

export const SyntheticAutoComplete: FC<SyntheticAutoCompleteProps<AutocompleteOption>> = ({
  name,
  objectCode,
  placeholder,
  disabled,
  className,
  value,
  valueType,
  onChange,
  isOptionEqualToValue = (value, option) => value.id === option.id,
  onLoadEnum,
  label,
  labelPlacement,
  labelSx,
  inputSx,
  dirtyFields,
  invalid,
  isDialogWindow,
  popupIcon,
  inputProps,
  smallPopupIconPadding = true,
  readOnly,
  ...props
}) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const isUpdated = className === UPDATES_ROW_CLASSNAME
  const [optionValue, setOptionValue] = useState<AutocompleteOption | null>(null)

  useEffect(() => {
    const matchingOption = props.options.find(option => option.id === value?.toString())

    if (matchingOption) {
      setOptionValue(matchingOption)

      return
    }

    if (value) {
      setOptionValue({
        id: value,
        label: value,
      } as AutocompleteOption)
    }
  }, [value, props.options])

  const handleChangeAutocomplete = (details: AutocompleteOption) => {
    if (readOnly) {
      return
    }

    onChange?.(details.id)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) {
      return
    }
  }

  const handleClearIcon = () => {
    setOptionValue(null)
    onChange?.(null)
  }

  const handleDropDownOpen = (event: SyntheticEvent<Element, Event>) => {
    const eventTarget = event.target as HTMLElement

    if (readOnly && (eventTarget.tagName === 'INPUT' || eventTarget.tagName === 'DIV')) {
      return
    }

    setIsOpen(true)
  }

  const handleDropDownClose = () => setIsOpen(false)

  const renderInput = () => {
    return (
      <AutoComplete
        fullWidth
        disableClearable={disabled || readOnly}
        isOptionEqualToValue={isOptionEqualToValue}
        open={isOpen}
        popupIcon={popupIcon}
        size='small'
        value={optionValue}
        onChange={handleChangeAutocomplete}
        onClose={handleDropDownClose}
        onOpen={handleDropDownOpen}
        {...props}
        componentsProps={{
          clearIndicator: {
            onClick: handleClearIcon,
          },
          popper: {
            sx: {
              zIndex: theme => theme.zIndex.tooltip,
              ...getAutoCompletePopperSxByDisplayMethod(isDialogWindow, readOnly, theme),
            },
          },
        }}
        renderInput={params => (
          <TextField
            {...params}
            className={className}
            disabled={disabled}
            error={invalid}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {inputProps?.startAdornment}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            sx={theme => ({
              ...generalFieldStylesByMode(theme, dirtyFields, name, isUpdated, isDialogWindow),
              '& .MuiAutocomplete-endAdornment': {
                right: smallPopupIconPadding ? '-14px !important' : '2px !important',
                ...(smallPopupIconPadding ? { top: '-2px !important' } : {}),
              },
              '& .MuiOutlinedInput-root': {
                backgroundColor: readOnly
                  ? theme.palette.background.hovered
                  : theme.palette.background.paper,
              },
            })}
            onChange={handleChange}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id} style={{ paddingRight: 0 }} title={option.label}>
              <Box sx={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {option.label}
              </Box>
            </li>
          )
        }}
        sx={{
          '& .MuiButtonBase-root': {
            transform: 'none',
            padding: isDialogWindow ? 0 : '2px',
          },
          '.MuiAutocomplete-popupIndicator': {
            marginRight: '10px',
          },
        }}
      />
    )
  }

  return (
    <Grid container item justifyContent='space-between'>
      <FormLabel
        control={renderInput()}
        label={label}
        labelPlacement={labelPlacement}
        labelSx={labelSx}
        name={name}
      />
    </Grid>
  )
}
